<app-sidebar class="hidden sm:block"></app-sidebar>
<div class="sm:ml-64 mt-14 bg-gray-100 min-h-screen">
  <div class="p-4 sm:p-6 lg:p-8">
    <h1 class="font-bold text-dagblue uppercase text-2xl sm:text-3xl mb-6">
      Historial De Recolecciones
    </h1>

    <!-- Filtros -->
    <div class="bg-transparent p-4 rounded-lg shadow-md mb-6">
      <div
        class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4"
      >
        <input
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
        />
        <input
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
        />
        <select
          class="border-2 border-gray-400 rounded-full p-2 text-gray-500 text-sm sm:text-base w-full sm:w-auto focus:outline-none focus:ring-2 focus:ring-dagblue"
        >
          <option value="">Todos</option>
          <option value="Pendiente">Pendiente</option>
          <option value="Pagado">Pagado</option>
        </select>
        <button
          class="bg-[#D6542B] text-white px-6 py-2 rounded-full font-bold text-sm sm:text-base w-full sm:w-auto hover:bg-blue-900 transition"
        >
          Filtrar
        </button>
        <button
          (click)="openModal()"
          class="bg-[#D6542B] text-white px-6 py-2 rounded-full font-bold text-sm sm:text-base w-full sm:w-auto hover:bg-blue-900 transition"
        >
          Crear Recolección
        </button>
      </div>
    </div>

    <!-- Tabla Historial -->
    <div class="bg-[#F0F0F0] rounded-lg shadow-md overflow-x-auto">
      <table class="w-full text-sm sm:text-base">
        <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th class="px-4 py-2 text-left">#</th>
            <th class="px-4 py-2 text-left">Fecha</th>
            <th class="px-4 py-2 text-left">Licenciatario</th>
            <th class="px-4 py-2 text-left">Usuario</th>
            <th class="px-4 py-2 text-left">Nombre Remitente</th>
            <th class="px-4 py-2 text-left">Teléfono</th>
            <th class="px-4 py-2 text-left">Status</th>
            <th class="px-4 py-2 text-left">Recibo</th>
            <th class="px-4 py-2 text-left">Paquetería</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 text-gray-500">
          <!-- <tr *ngFor="let item of historial" class="hover:bg-gray-50">
            <td class="px-4 py-2">{{ item.id }}</td>
            <td class="px-4 py-2">{{ item.fecha }}</td>
            <td class="px-4 py-2">{{ item.licenciatario }}</td>
            <td class="px-4 py-2">{{ item.usuario }}</td>
            <td class="px-4 py-2">{{ item.nombreRemitente }}</td>
            <td class="px-4 py-2">{{ item.telefono }}</td>
            <td class="px-4 py-2">
              <span class="px-2 py-1 rounded-full text-green-600 font-bold">{{ item.status }}</span>
            </td>
            <td class="px-4 py-2 text-center">
              <button class="text-red-500 text-xl">📄</button>
            </td>
            <td class="px-4 py-2">
              <a href="#" class="text-orange-600 hover:underline">Ver</a>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>

  <!-- Modal Overlay -->
  <div *ngIf="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center">
    <div class="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto mx-auto">
      <div class="p-6">
       <!-- Pestañas de navegación numeradas -->
        <div class="flex items-center space-x-6 mb-6">
          <div class="flex items-center space-x-2">
            <div
              class="w-8 h-8 flex items-center justify-center font-bold rounded-full"
              [ngClass]="{
                'bg-white text-white': currentStep === 1,
                'bg-dagblue text-white': currentStep > 1,
                'bg-gray-300 text-gray-600': currentStep < 1
              }"
            >
              1
            </div>
            <span [ngClass]="{ 'text-dagblue font-semibold': currentStep >= 1, 'text-gray-500': currentStep < 1 }">
              Información remitente
            </span>
          </div>

          <div class="flex items-center space-x-2">
            <div
              class="w-8 h-8 flex items-center justify-center font-bold rounded-full"
              [ngClass]="{
                'bg-white text-dagblue': currentStep === 2,
                'bg-dagblue text-white': currentStep > 2,
                'bg-gray-300 text-gray-600': currentStep < 2
              }"
            >
              2
            </div>
            paquetes
            <span [ngClass]="{ 'text-dagblue font-semibold': currentStep >= 2, 'text-gray-500': currentStep < 2 }">
              Registrar paquetes
            </span>
          </div>

          <div class="flex items-center space-x-2">
            <div
              class="w-8 h-8 flex items-center justify-center font-bold rounded-full"
              [ngClass]="{
                'bg-white text-dagblue': currentStep === 3,
                'bg-dagblue text-white': currentStep > 3,
                'bg-gray-300 text-gray-600': currentStep < 3
              }"
            >
              3
            </div>
            <span [ngClass]="{ 'text-dagblue font-semibold': currentStep >= 3, 'text-gray-500': currentStep < 3 }">
              Resumen
            </span>
          </div>

          <div class="flex items-center space-x-2">
            <div
              class="w-8 h-8 flex items-center justify-center font-bold rounded-full"
              [ngClass]="{
                'bg-white text-dagblue': currentStep === 4,
                'bg-dagblue text-white': currentStep > 4,
                'bg-gray-300 text-gray-600': currentStep < 4
              }"
            >
              4
            </div>
            <span [ngClass]="{ 'text-dagblue font-semibold': currentStep >= 4, 'text-gray-500': currentStep < 4 }">
              Seleccionar medio de pago
            </span>
          </div>

          <div class="flex items-center space-x-2">
            <div
              class="w-8 h-8 flex items-center justify-center font-bold rounded-full"
              [ngClass]="{
                'bg-white text-dagblue': currentStep === 5,
                'bg-dagblue text-white': currentStep > 5,
                'bg-gray-300 text-gray-600': currentStep < 5
              }"
            >
              5
            </div>
            <span [ngClass]="{ 'text-dagblue font-semibold': currentStep >= 5, 'text-gray-500': currentStep < 5 }">
              Realizar pago
            </span>
          </div>


          <div class="flex items-center space-x-2">
            <div
              class="w-8 h-8 flex items-center justify-center font-bold rounded-full"
              [ngClass]="{
                'bg-white text-dagblue': currentStep === 6,
                'bg-dagblue text-white': currentStep > 6,
                'bg-gray-300 text-gray-600': currentStep < 6
              }"
            >
              6
            </div>
            <span [ngClass]="{ 'text-dagblue font-semibold': currentStep >= 6, 'text-gray-500': currentStep < 6 }">
              Resumen pago
            </span>
          </div>
        </div>

        
  
        <!-- Contenido Dinámico por Paso -->
        <div *ngIf="currentStep === 1">
          <!-- Información del Remitente -->
          <div [formGroup]="senderInfoForm">
            <h2 class="text-2xl font-bold mb-6 text-center">Datos del remitente</h2>
        
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
              <input
                formControlName="senderName"
                placeholder="Nombre Remitente"
                class="w-full px-4 py-2 border rounded-md"
                type="text"
              />
              <input
                formControlName="senderPhone"
                placeholder="Teléfono Remitente"
                class="w-full px-4 py-2 border rounded-md"
                type="tel"
                maxlength="10"
                pattern="\d{10}"
                (input)="validatePhone($event)"
              />
            </div>
        
            <!-- Botón Guardar -->
            <div class="text-center mb-4">
              <button 
                class="w-full bg-orange-500 text-white py-2 rounded-md font-bold hover:bg-dagblue"
                (click)="nextStep()"
                [disabled]="senderInfoForm.invalid"
              >
                Guardar
              </button>
            </div>
        
            <!-- Botones de navegación -->
            <div class="flex justify-between">
              <button class="text-gray-500" (click)="prevStep()"></button>
              <button class="text-gray-500" (click)="closeModal()">Ir A Inicio</button>
            </div>
          </div>
        </div>

        <!-- paso 2 -->
        <div *ngIf="currentStep === 2">
          <!-- Título de la sección -->
          <h2 class="text-2xl font-bold mb-6 text-center">Registrar Paquetes</h2>
        
          <!-- Formulario para agregar paquetes -->
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4" [formGroup]="packageRegistrationForm">
            <input
              formControlName="trackingNumber"
              placeholder="N° Tracking"
              class="w-full px-4 py-2 border rounded-md"
              type="text"
            />
            <input
              formControlName="courier"
              placeholder="Paquetería"
              list="courierOptions"
              class="w-full px-4 py-2 border rounded-md"
              type="text"
            />
            <datalist id="courierOptions">
              <option *ngFor="let option of courierOptions" [value]="option"></option>
            </datalist>
          </div>
        
          <!-- Botón para agregar paquete -->
          <div class="mb-6">
            <button
              (click)="addPackage(packageRegistrationForm.value)"
              class="bg-orange-500 text-white px-6 py-2 rounded-md font-bold hover:bg-dagblue"
              [disabled]="packageRegistrationForm.invalid"
            >
              <span class="mr-2">+</span> Agregar Paquete
            </button>
          </div>
        
          <!-- Lista de paquetes agregados -->
          <div class="mb-6">
            <p class="font-semibold mb-2">Cada paquete tiene un costo de ${{ packagePrice }} MXN</p>
            <ul>
              <li *ngFor="let paquete of paquetes; let i = index" class="flex justify-between items-center mb-2">
                <div>
                  <span class="block font-semibold">Paquetería: {{ paquete.courier }}</span>
                  <span class="block">N° Tracking: {{ paquete.trackingNumber }}</span>
                </div>
                <button (click)="removePackage(i)" class="text-red-500 hover:text-red-700">
                  🗑️
                </button>
              </li>
            </ul>
          </div>
        
          <!-- Botón para editar el precio del paquete -->
          <div class="text-center mb-4">
            <button 
              class="bg-blue-500 text-white px-6 py-2 rounded-md font-bold hover:bg-blue-700"
              (click)="openPriceModal()"
            >
              Editar Precio del Paquete
            </button>
          </div>
        
          <!-- Total y Botón Confirmar -->
          <div class="flex justify-between items-center mb-6">
            <p class="text-xl font-bold text-blue-800">Total: ${{ paquetes.length * packagePrice }} MXN</p>
          </div>
        
          <div class="text-center mb-4">
            <button 
              class="w-full bg-orange-500 text-white py-2 rounded-md font-bold hover:bg-dagblue"
              (click)="nextStep()"
              [disabled]="paquetes.length === 0"
            >
              Confirmar
            </button>
          </div>
        
          <!-- Botones de navegación -->
          <div class="flex justify-between">
            <button class="text-gray-500" (click)="prevStep()">Atrás</button>
            <button class="text-gray-500" (click)="closeModal()">Ir A Inicio</button>
          </div>
        </div>
        
        <!-- Modal para editar el precio del paquete -->
        <div *ngIf="isPriceModalOpen" class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div class="bg-white p-6 rounded-lg shadow-lg w-80">
            <h3 class="text-xl font-bold mb-4">Editar Precio del Paquete</h3>
            <input
              [(ngModel)]="packagePrice"
              type="number"
              min="0"
              class="w-full px-4 py-2 border rounded-md mb-4"
              placeholder="Nuevo Precio"
            />
            <div class="flex justify-end">
              <button class="bg-gray-300 px-4 py-2 rounded-md mr-2" (click)="closePriceModal()">Cancelar</button>
              <button class="bg-blue-500 text-white px-4 py-2 rounded-md" (click)="savePrice()">Guardar</button>
            </div>
          </div>
        </div>
        
      
        <!-- Step 3: Summary -->
        <div *ngIf="currentStep === 3">
          <!-- Título de la sección -->
          <h2 class="text-2xl font-bold mb-6 text-center">Resumen</h2>

          <div class="mb-4">
            <p class="font-semibold">Nombre: {{ senderInfoForm.get('senderName')?.value }}</p>
            <p class="font-semibold">Teléfono: {{ senderInfoForm.get('senderPhone')?.value }}</p>
          </div>

          <ul class="mb-6">
            <li *ngFor="let paquete of paquetes" class="border-b py-2">
              <span class="block font-semibold">N° Tracking: {{ paquete.trackingNumber }}</span>
              <span class="block">Paquetería: {{ paquete.courier }}</span>
            </li>
          </ul>

          <div class="text-center mb-8">
            <button
              class="w-full bg-orange-500 text-white py-2 rounded-md font-bold hover:bg-dagblue transition"
              (click)="confirmAndNextStep3()"
            >
              Confirmar
            </button>
          </div>

          <!-- Botones de navegación -->
          <div class="flex justify-between">
            <button class="text-gray-500" (click)="prevStep()">Atrás</button>
            <button class="text-gray-500" (click)="closeModal()">Ir A Inicio</button>
          </div>
        </div>

  
        <!-- Step 4: Receipt -->
        <div *ngIf="currentStep === 4">
          <!-- Sección de Saldo -->
          <div class="bg-gray-100 p-4 rounded-lg shadow-sm flex items-center justify-between mb-6">
            <div>
              <h3 class="font-bold text-dagblue">Saldo Dagpacket</h3>
              <p class="text-gray-500">Utiliza tu saldo de Dagpacket para mayor facilidad</p>
            </div>
            <div class="flex justify-center items-center p-2 shadow-sm">
              <img 
                src="assets/images/Icon Dagpacket.png" 
                alt="Logo Dagpacket" 
                class="w-24 h-12 object-contain"
              />
            </div>                       
          </div>
        
          <!-- Campo para Nota -->
          <div class="mb-6">
            <input
              formControlName="note"
              placeholder="Nota"
              class="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-dagblue"
              type="text"
            />
          </div>
        
          <!-- Botón Confirmar -->
          <div class="text-center mb-8">
            <button
              class="w-full bg-orange-500 text-white py-2 rounded-md font-bold hover:bg-dagblue transition"
              (click)="confirmAndNextStep()"
            >
              Confirmar
            </button>
          </div>

          <!-- Botones de navegación -->
          <div class="flex justify-between">
            <button class="text-gray-500" (click)="prevStep()">Atrás</button>
            <button class="text-gray-500" (click)="closeModal()">Ir A Inicio</button>
          </div>
        
          
        </div>
        
        
        <!-- Step 5: Realizar Pago -->
        <div *ngIf="currentStep === 5">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <!-- Sección de Lista de Paquetes -->
            <div class="bg-white p-6 rounded-lg shadow-sm">
              <h2 class="text-2xl font-bold mb-4">Lista de Paquetes</h2>
              <div *ngFor="let paquete of paquetes" class="border-b py-2">
                <p class="text-gray-700 font-semibold">
                  {{ paquete.courier }} - N° {{ paquete.trackingNumber }}
                </p>
              </div>
              <div class="mt-4">
                <p class="font-semibold text-gray-700">Subtotal: ${{ paquetes.length * 20 }}</p>
                <p class="font-semibold text-gray-700">Descuentos: $0</p>
                <p class="font-semibold text-gray-700">Total a pagar user: ${{ paquetes.length * 20 }}</p>
                <p class="font-bold text-lg mt-2">Total a pagar cliente: ${{ paquetes.length * 20 }}</p>
              </div>
            </div>

            <!-- Sección de Pago con Saldo Dagpacket -->
            <div class="bg-gray-100 p-6 rounded-lg shadow-sm">
              <h3 class="text-2xl font-bold mb-4 text-center">Dagpacket</h3>
              <div class="flex justify-center mb-4">
                <div class="flex justify-center items-center p-2 shadow-sm">
                  <img 
                    src="assets/images/Icon Dagpacket.png" 
                    alt="Logo Dagpacket" 
                    class="w-24 h-12 object-contain"
                  />
                </div>   
              </div>
              <p class="text-gray-700 text-center mb-6">
                Tu saldo de paquetería es de <span class="font-bold">$920</span>
              </p>
              <form [formGroup]="nipForm">
                <input
                  formControlName="nip"
                  placeholder="NIP"
                  class="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-dagblue"
                  type="password"
                />
                <button
                  class="w-full bg-orange-500 text-white py-2 rounded-md font-bold hover:bg-dagblue transition"
                  [disabled]="nipForm.invalid"
                  (click)="confirmPayment5()"
                >
                  Confirmar Pago
                </button>


              </form>
            </div>
          </div>

          <!-- Botones de navegación -->
          <div class="flex justify-between">
            <button class="text-gray-500" (click)="prevStep()">Atrás</button>
            <button class="text-gray-500" (click)="closeModal()">Ir A Inicio</button>
          </div>

         
        </div>


        <!-- Step 6: Resumen de Pago -->
        <div *ngIf="currentStep === 6" id="card" class="bg-white p-6 rounded-lg shadow-md">
          <h2 class="text-center text-xl font-bold mb-4">Recepción paquetería multimarca</h2>
        
          <div class="flex justify-center mb-4">
            <div class="flex justify-center items-center p-2 shadow-sm">
              <img 
                src="assets/images/Icon Dagpacket.png" 
                alt="Logo Dagpacket" 
                class="w-24 h-12 object-contain"
              />
            </div>   
          </div>
        
          <p class="text-center text-gray-700 mb-4">
            {{ currentDateTime }}
          </p>
        
          <div class="text-center mb-6">
            <p class="font-semibold">Remitente: {{ senderInfoForm.get('senderName')?.value }}</p>
            <p class="font-semibold">Teléfono: {{ senderInfoForm.get('senderPhone')?.value }}</p>
          </div>
        
          <div class="text-center font-bold text-lg mb-4">
            N° Paquetes: {{ paquetes.length }}
          </div>
        
          <div class="flex justify-between mb-2">
            <p class="font-semibold text-gray-700">N° Tracking: {{ paquetes[0]?.trackingNumber }}</p>
            <p class="font-semibold text-gray-700">Paquetería: {{ paquetes[0]?.courier }}</p>
          </div>
        
          <p class="text-center font-semibold mb-4">Medio de pago: Saldo Dagpacket</p>
        
          <hr class="mb-4">
        
          <div class="flex justify-between text-lg font-bold mb-8">
            <span>Total</span>
            <span>${{ paquetes.length * packagePrice }}</span>
          </div>          
        
          <!-- Botón para Descargar la Tarjeta -->
          <div class="text-center mt-8">
            <button 
              class="bg-blue-500 text-white px-6 py-2 rounded-md font-bold hover:bg-blue-700 transition"
              (click)="downloadCard()"
            >
              Descargar
            </button>
          </div>
        
          <!-- Botón para Volver al Inicio -->
          <div class="text-center mt-4">
            <button 
              class="bg-orange-500 text-white px-6 py-2 rounded-md font-bold hover:bg-dagblue transition"
              (click)="closeModal()"
            >
              Ir A Inicio
            </button>
          </div>
        </div>
        
        

      </div>
    </div>
  </div>  
  
</div>
