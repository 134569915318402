import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface RecolectData {
 user_id: string;
 name: string;
 telephone: string;
 tracking_number: string;
 delivery: string;
 note: string;
 status: string;
 image64: string;
}

@Injectable({
 providedIn: 'root'
})
export class RecolectService {
 private apiUrl = `${environment.apiUrl}/recolect`;

 constructor(private http: HttpClient) {}

 createRecolect(data: RecolectData): Observable<any> {
   return this.http.post(`${this.apiUrl}/create`, data);
 }

 getAllRecolects(page: number = 1, limit: number = 10, searchTerm: string = ''): Observable<any> {
   const params = new HttpParams()
     .set('page', page.toString())
     .set('limit', limit.toString())
     .set('searchTerm', searchTerm);
   
   return this.http.get(`${this.apiUrl}/all`, { params });
 }

 getRecolectsByUser(userId: string): Observable<any> {
  return this.http.get(`${this.apiUrl}/user/${userId}`);
}
}