import { Component, OnInit } from '@angular/core';
import { DealerService } from 'src/app/_services/Dealer/dealer.service';
import { DeliveryService } from 'src/app/_services/delivery/delivery.service';
@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.scss'],
})
export class DealerComponent implements OnInit {
  repartidores: any[] = [];
  isLoading: boolean = false;
  errorMessage: string | null = null;
  viewModal: boolean = false;
  deliveryDetails: any[] = [];
  currentFilter: string = 'todos';
  filteredShipments: any[] = [];

  constructor(
    private dealerService: DealerService,
    private deliveryService: DeliveryService
  ) {}

  ngOnInit() {
    this.loadDeliveryUsers();
  }

  loadDeliveryUsers() {
    this.isLoading = true;
    this.dealerService.getDeliveryUsers().subscribe({
      next: (data) => {
        this.repartidores = data.data.users;
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error al obtener los datos:', error);
        this.errorMessage = 'Error al cargar los datos.';
        this.isLoading = false;
      },
    });
  }
  viewDetails(id: string) {
    this.viewModal = true;
    this.deliveryService.viewShipmentsByDeliveryman({ user_id: id }).subscribe({
      next: (data) => {
        this.deliveryDetails = data.data;
        this.filterShipments('todos'); // Mostrar todos inicialmente
      },
      error: (error) => {
        console.error('Error al obtener los datos:', error);
      },
    });
  }
  filterShipments(status: string) {
    this.currentFilter = status;
    
    if (status === 'todos') {
      this.filteredShipments = this.deliveryDetails;
    } else {
      this.filteredShipments = this.deliveryDetails.filter(
        shipment => shipment.titulo.toLowerCase() === status.toLowerCase()
      );
    }
  }
}
