import { Component, OnInit } from '@angular/core';
import { CashRegisterService } from 'src/app/_services/Cashiers/cash-register.service';

@Component({
  selector: 'app-cash-register-list-license',
  templateUrl: './cash-register-list-license.component.html',
  styleUrls: ['./cash-register-list-license.component.scss'],
})
export class CashRegisterListLicenseComponent {
  cashRegisters: any[] = [];
  currentPage = 1;
  totalPages = 1;
  itemsPerPage = 10;
  totalItems = 0;
  loading = false;
  error: string | null = null;
  startDate: string = '';
  endDate: string = '';
  Math = Math;
  id: any;

  constructor(private cashRegisterService: CashRegisterService) {}

  ngOnInit(): void {
    this.id = localStorage.getItem('id');
    this.loadCashRegisters();
  }

  loadCashRegisters(): void {
    this.loading = true;
    this.cashRegisterService
      .getAllCashRegistersByLicense(
        this.currentPage,
        this.itemsPerPage,
        this.id,
        this.startDate,
        this.endDate
      )
      .subscribe(
        (response) => {
          console.log('Registros de caja raw:', response.data);
          this.cashRegisters = response.data.cashRegisters;
          console.log('Registros de caja:', this.cashRegisters);
          this.currentPage = response.data.currentPage;
          this.totalPages = response.data.totalPages;
          this.totalItems = response.data.totalRegisters;
          this.loading = false;
        },
        (error) => {
          this.error = 'Error al cargar los registros de caja';
          this.loading = false;
        }
      );
  }

  getSumByType(transactions: any[], type: string): number {
    return transactions
      .filter(t => {
        const desc = t.description?.toLowerCase() || '';
        switch(type.toLowerCase()) {
          case 'recarga':
            return desc.includes('recarga') || desc.includes('telefonica');
          case 'envio':
            return desc.includes('envío') || desc.includes('envios') || desc.includes('envio');
          case 'servicio':
            return desc.includes('servicio');
          default:
            return false;
        }
      })
      .reduce((sum, t) => sum + Number(t.amount.$numberDecimal || 0), 0);
  }

  getSumByTypePayment(transactions: any[], type: string): number {
    return transactions
      .filter(t => {
        const desc = t.payment_method?.toLowerCase() || '';
        console.log(desc);
        switch(type.toLowerCase()) {
          case 'saldo':
            return desc.includes('saldo');
          case 'td-debito':
            return desc.includes('td-debito');
          case 'td-credito':
            return desc.includes('td-credito');
          default:
            return false;
        }
      })
      .reduce((sum, t) => sum + Number(t.amount.$numberDecimal || 0), 0);
  }

  // Función para obtener el total general
  getTotalSum(transactions: any[]): number {
    return transactions
      .reduce((sum, t) => sum + Number(t.amount.$numberDecimal || 0), 0);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadCashRegisters();
  }

  applyDateFilter(): void {
    this.currentPage = 1;
    this.loadCashRegisters();
  }

  clearDateFilter(): void {
    this.startDate = '';
    this.endDate = '';
    this.currentPage = 1;
    this.loadCashRegisters();
  }

  formatCurrency(amount: number): string {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(amount);
  }
}
