<app-sidebar></app-sidebar>
<div class="ml-64 mt-16">
  <div
    *ngIf="showPinModal"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
  >
    <div
      class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white z-50"
    >
      <h3 class="text-lg font-bold mb-4">Ingresar NIP</h3>
      <form (ngSubmit)="validateNip()">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="nipInput"
            >NIP de seguridad</label
          >
          <input
            type="password"
            id="nipInput"
            [(ngModel)]="enteredPin"
            name="nipInput"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div class="flex items-center justify-between">
          <button
            class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Validar NIP
          </button>
          <button
            class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            (click)="closePinModal()"
            type="button"
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="container mx-auto px-4 py-8">
    <h2 class="text-3xl font-bold mb-6 text-gray-800">
      Pagar Envíos Pendientes
    </h2>

    <div
      *ngIf="errorMessage"
      class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6"
    >
      {{ errorMessage }}
    </div>

    <div
      *ngIf="successMessage"
      class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6"
    >
      {{ successMessage }}
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- Columna izquierda: Lista de envíos pendientes -->
      <div>
        <div
          *ngIf="pendingShipments.length === 0"
          class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
        >
          <p class="font-bold">No hay envíos pendientes de pago.</p>
        </div>

        <div
          *ngFor="let shipment of pendingShipments"
          class="bg-white shadow-md rounded-lg mb-6 overflow-hidden"
        >
          <div
            class="bg-gray-100 px-6 py-4 border-b border-gray-200 flex justify-between items-center"
          >
            <h3 class="text-xl font-semibold text-gray-800">
              Envío #{{ shipment.trackingNumber }}
            </h3>
            <p class="text-gray-600">Folio {{ shipment._id }}</p>
            <div class="flex items-center">
              <input
                type="checkbox"
                [id]="'shipment-' + shipment._id"
                [(ngModel)]="shipment.selected"
                (change)="updateSelectedShipments()"
                class="form-checkbox h-5 w-5 text-blue-600"
              />
              <label
                [for]="'shipment-' + shipment._id"
                class="ml-2 text-gray-700"
                >Seleccionar</label
              >
            </div>
          </div>

          <div class="p-6">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div class="address-section">
                <h4 class="text-lg font-semibold mb-4 text-black-700">Desde:</h4>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Nombre:</span>
                  <p class="text-gray-600">{{ shipment.from.name }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Correo:</span>
                  <p class="text-gray-600">{{ shipment.from.email }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Teléfono:</span>
                  <p class="text-gray-600">{{ shipment.from.phone }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Dirección:</span>
                  <p class="text-gray-600">
                    {{ shipment.from.street }} #{{ shipment.from.external_number }}, int #{{ shipment.from.internal_number }}
                  </p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Ciudad:</span>
                  <p class="text-gray-600">{{ shipment.from.city }}, {{ shipment.from.state }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">C.P.:</span>
                  <p class="text-gray-600">{{ shipment.from.zip_code }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">País:</span>
                  <p class="text-gray-600">{{ shipment.from.country }}</p>
                </div>
              </div>
            
              <div class="address-section">
                <h4 class="text-lg font-semibold mb-4 text-black-700">Hacia:</h4>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Nombre:</span>
                  <p class="text-gray-600">{{ shipment.to.name }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Correo:</span>
                  <p class="text-gray-600">{{ shipment.to.email }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Teléfono:</span>
                  <p class="text-gray-600">{{ shipment.to.phone }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Dirección:</span>
                  <p class="text-gray-600">
                    {{ shipment.to.street }} #{{ shipment.to.external_number }}, int #{{ shipment.to.internal_number }}
                  </p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">Ciudad:</span>
                  <p class="text-gray-600">{{ shipment.to.city }}, {{ shipment.to.state }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">C.P.:</span>
                  <p class="text-gray-600">{{ shipment.to.zip_code }}</p>
                </div>
              
                <div class="flex items-center mb-2">
                  <span class="font-medium mr-2">País:</span>
                  <p class="text-gray-600">{{ shipment.to.country }}</p>
                </div>
              </div>
            </div>

            <div class="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p class="text-gray-700">
                  <span class="font-semibold">Tipo de envío:</span>
                  {{ shipment.shipment_type }}
                </p>
                <p class="text-gray-700">
                  <span class="font-semibold">Estado:</span>
                  {{ shipment.status }}
                </p>
                <p class="text-gray-700">
                  <span class="font-semibold">Proveedor:</span>
                  {{ shipment.provider }}
                </p>
                <p class="text-gray-700">
                  <span class="font-semibold">Api Proveedor:</span>
                  {{ shipment.apiProvider }}
                </p>
                <p class="text-gray-700">
                  <span class="font-semibold">Descripción del paquete:</span>
                  {{ shipment.description }}
                </p>
                <p class="text-gray-700">
                  <span class="font-semibold">Fecha de distribución:</span>
                  {{ shipment.distribution_at | date : "medium" }}
                </p>
              </div>

              <div
                *ngIf="shipment.packing.answer === 'Si'"
                class="bg-blue-50 p-4 rounded-md"
              >
                <h4 class="text-lg font-semibold mb-2 text-blue-800">
                  Empaque:
                </h4>
                <p class="text-blue-700">
                  Tipo: {{ shipment.packing.packing_type }}
                </p>
                <p class="text-blue-700">
                  Costo:
                  {{
                    shipment.packing.packing_cost.$numberDecimal
                      | currency : "MXN"
                  }}
                </p>
              </div>
            </div>

            <div class="mt-6 bg-gray-50 p-4 rounded-md">
              <p class="text-xl font-bold text-gray-800 mt-2">
                <span class="font-semibold">Precio total:</span>
                {{ shipment.price.$numberDecimal | currency : "MXN" }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Columna derecha: Método de pago -->
      <div>
        <h3 class="text-2xl font-bold mb-4 text-gray-800">Método de Pago</h3>
        <div class="flex space-x-4 mb-6">
          <button
            (click)="selectPaymentMethod('saldo')"
            [ngClass]="{
              'bg-blue-600 text-white': selectedPaymentMethod === 'saldo',
              'bg-gray-200 text-gray-700': selectedPaymentMethod !== 'saldo'
            }"
            class="px-6 py-2 rounded-md transition duration-300 ease-in-out"
          >
            Saldo de Cuenta
          </button>
          <button
            (click)="selectPaymentMethod('td-debito')"
            [ngClass]="{
              'bg-blue-600 text-white': selectedPaymentMethod === 'td-debito',
              'bg-gray-200 text-gray-700': selectedPaymentMethod !== 'td-debito'
            }"
            class="px-6 py-2 rounded-md transition duration-300 ease-in-out"
          >
            TD/Debito
          </button>
          <button
            (click)="selectPaymentMethod('td-credito')"
            [ngClass]="{
              'bg-blue-600 text-white': selectedPaymentMethod === 'td-credito',
              'bg-gray-200 text-gray-700':
                selectedPaymentMethod !== 'td-credito'
            }"
            class="px-6 py-2 rounded-md transition duration-300 ease-in-out"
          >
            TD/Credito
          </button>
        </div>

        <div
          *ngIf="
            selectedPaymentMethod === 'td-debito' ||
            selectedPaymentMethod === 'td-credito'
          "
          class="mb-6"
        >
          <label
            for="transactionNumber"
            class="block text-sm font-medium text-gray-700 mb-2"
            >Número de Transacción</label
          >
          <input
            type="text"
            id="transactionNumber"
            [(ngModel)]="transactionNumber"
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Ingrese el número de transacción"
          />
        </div>

        <div class="bg-gray-100 p-6 rounded-lg mb-6">
          <h3 class="text-2xl font-bold text-gray-800">
            Total a pagar: {{ calculateSelectedTotal() | currency : "MXN" }}
          </h3>
        </div>

        <!-- NIP -->
        <div
          *ngIf="showPinModal"
          class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
        >
          <div
            class="relative top-20 mx-auto p-5 border w-fit shadow-lg rounded-md bg-white z-50"
          >
            <h3 class="text-lg font-bold mb-4">Ingresar NIP</h3>
            <form (ngSubmit)="validateNip()">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="nipInput"
                  >NIP de seguridad</label
                >
                <input
                  type="password"
                  id="nipInput"
                  [(ngModel)]="enteredPin"
                  name="nipInput"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div class="flex items-center justify-between">
                <button
                  class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Validar NIP
                </button>
                <button
                  class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  (click)="closePinModal()"
                  type="button"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>

        <!-- Botón para abrir el modal -->
        <button
          (click)="openPinModal()"
          [disabled]="
            isProcessing ||
            generatingGuides ||
            !selectedPaymentMethod ||
            getSelectedShipments().length === 0 ||
            (isCardPayment() &&
              (!transactionNumber || transactionNumber.length < 3)) ||
            disableGenerateButton
          "
          [ngClass]="{
            'bg-green-600 hover:bg-green-700':
              !isProcessing &&
              !generatingGuides &&
              selectedPaymentMethod &&
              getSelectedShipments().length > 0 &&
              (!isCardPayment() ||
                (transactionNumber && transactionNumber.length >= 3)) &&
              !disableGenerateButton,
            'bg-gray-400 cursor-not-allowed':
              isProcessing ||
              generatingGuides ||
              !selectedPaymentMethod ||
              getSelectedShipments().length === 0 ||
              (isCardPayment() &&
                (!transactionNumber || transactionNumber.length < 3)) ||
              disableGenerateButton
          }"
          class="w-full py-3 text-white font-bold rounded-md transition duration-300 ease-in-out"
        >
          {{
            isProcessing
              ? "Procesando..."
              : generatingGuides
              ? "Generando guías..."
              : "Generar Guías y Procesar Pago"
          }}
        </button>

        <!--
        <button 
          (click)="cancelSelectedShipments()" 
          [disabled]="isProcessing || generatingGuides || getSelectedShipments().length === 0"
          class="w-full mt-4 py-3 text-white font-bold rounded-md transition duration-300 ease-in-out"
          [ngClass]="{'bg-red-600 hover:bg-red-700': !isProcessing && !generatingGuides && getSelectedShipments().length > 0, 'bg-gray-400 cursor-not-allowed': isProcessing || generatingGuides || getSelectedShipments().length === 0}">
          Cancelar Envíos Seleccionados
        </button>
        -->
      </div>
    </div>

    <div
      *ngIf="generatingGuides"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center"
    >
      <div class="bg-white p-6 rounded-lg shadow-lg text-center">
        <h4 class="text-lg font-bold mb-4 text-gray-700">
          Generando guías de envío...
        </h4>
        <div class="loader"></div>
      </div>
    </div>
  </div>
</div>
