<app-sidebar></app-sidebar>
<div class="ml-64 mt-14">
  <div class="container mx-auto p-4">
    <div class="flex justify-between items-center mb-4">
      <label class="flex items-center font-bold mb-4">
        <input type="checkbox" class="mr-2" />
        Mostrar nuevo dashboard
      </label>
      <div class="flex space-x-4">
        <button (click)="toggleDownloadForm()" class="btn-action">
          Descargar Resumen Utilidades
        </button>
        <button class="btn-action">Reporte Wallets</button>
        <div class="flex items-center space-x-2 font-bold mb-4">
          <span>Datos obtenidos desde:</span>
          <input
            type="date"
            class="input-date"
            (change)="onDateChange($event)"
          />
          <button (click)="onApply()" class="btn-primary">Aplicar</button>
        </div>
      </div>
    </div>

    <div *ngIf="showDownloadForm" class="flex items-center space-x-4 card">
      <h2 class="text-center text-dagblue font-bold mb-4">
        Descargar Resumen Utilidades
      </h2>
      <div class="flex flex-col">
        <label class="text-gray-700">Desde</label>
        <input type="date" class="input-date" />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700">Hasta</label>
        <input type="date" class="input-date" />
      </div>
      <div>
        <button class="btn-primary">Descargar</button>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div class="card" (click)="openCancellationModal()">
        <div class="card-icon">
          <i class="fas fa-times-circle"></i>
        </div>
        <h2 class="card-title">Envios para cancelación</h2>
        <p class="card-value">{{ totalCancellations }}</p>
        <p class="card-status text-red-500">Pendientes</p>
      </div>
      <div class="card" routerLink="/displayRefills">
        <div class="card-icon">
          <i class="fas fa-file-alt"></i>
        </div>
        <h2 class="card-title">Solicitudes Recargas</h2>
        <p class="card-value">{{totalPendingRecharges}}</p>
        <p class="card-status text-red-500">Pendientes</p>
      </div>
      <div class="card" (click)="openPedidoModal()">
        <div class="card-icon">
          <i class="fas fa-file-invoice"></i>
        </div>
        <h2 class="card-title">Pedidos de Factura</h2>
        <p class="card-value">1</p>
        <p class="card-status text-red-500">Pendientes</p>
      </div>

      <div class="card col-span-1 md:col-span-2" (click)="openEnviosModal()">
        <div class="card-icon">
          <i class="fas fa-shipping-fast"></i>
        </div>
        <h2 class="card-title">Envíos</h2>
        <p class="card-value">{{ totalShipments }}</p>
        <p class="card-status text-red-500 mt-2">Total de envíos:</p>
        <p class="card-value">${{ globalProfit }}</p>
        <!-- Muestra la utilidad quincenal -->
        <p class="card-status text-green-500">Utilidad esta quincena</p>
      </div>

      <div class="card col-span-1 md:col-span-2" (click)="openEmpaquesModal()">
        <div class="card-icon">
          <i class="fas fa-box-open"></i>
        </div>
        <h2 class="card-title">Empaques</h2>
        <p class="card-value">
          {{ formatProfit(EmpaquequincenalProfit.packingProfit) }}
        </p>
        <p class="card-status text-green-500">Utilidad esta quincena</p>
      </div>

      <div class="card" (click)="openUserModal()">
        <div class="card-icon">
          <i class="fas fa-users"></i>
        </div>
        <h2 class="card-title">Usuarios</h2>
        <p class="card-value">{{ totalUsers }}</p>
      </div>
      <div class="card" (click)="openPagoSModal()">
        <div class="card-icon">
          <i class="fas fa-wallet"></i>
        </div>
        <h2 class="card-title">Pago servicios</h2>
        <p class="card-value">$16.80</p>
        <p class="card-status text-green-500">Utilidad esta quincena</p>
      </div>
      <div class="card" (click)="openPagoRModal()">
        <div class="card-icon">
          <i class="fas fa-credit-card"></i>
        </div>
        <h2 class="card-title">Pago recarga</h2>
        <p class="card-value">
          {{ profitData | currency : "USD" : "symbol" : "1.2-2" }}
        </p>
        <p class="card-status text-green-500">Utilidad esta quincena</p>
      </div>
      <div class="card" (click)="openRepartoMModal()">
        <div class="card-icon">
          <i class="fas fa-truck"></i>
        </div>
        <h2 class="card-title">Reparto multimarca</h2>
        <p class="card-value">36</p>
      </div>

      <div class="card" (click)="openCuponesModal()">
        <div class="card-icon">
          <i class="fas fa-tag"></i>
        </div>
        <h2 class="card-title">Cupones</h2>
        <p class="card-value">41</p>
      </div>
      <div class="card" (click)="openDireccionesModal()">
        <div class="card-icon">
          <i class="fas fa-map-marker-alt"></i>
        </div>
        <h2 class="card-title">Direcciones</h2>
        <p class="card-value">{{ totalDirecciones }}</p>
      </div>
      <div class="card" (click)="openRecotizcionesModal()">
        <div class="card-icon">
          <i class="fas fa-redo-alt"></i>
        </div>
        <h2 class="card-title">Recotizaciones</h2>
        <p class="card-value">7</p>
      </div>
      <div class="card" (click)="openRembolsoModal()">
        <div class="card-icon">
          <i class="fas fa-undo-alt"></i>
        </div>
        <h2 class="card-title">Reembolsos por CLIP</h2>
        <p class="card-value">0</p>
        <p class="card-status text-red-500">Pendientes</p>
      </div>
    </div>
  </div>
</div>
<!-- Modal para mostrar la lista de usuarios -->
<div
  *ngIf="showUserModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold text-dagblue">Lista de Usuarios</h2>
        <button
          (click)="closeUserModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Barra de búsqueda -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input
          type="text"
          [(ngModel)]="searchTerm"
          (input)="onSearch()"
          placeholder="Buscar usuario..."
          class="flex-grow border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue mb-2 md:mb-0"
        />

        <div class="flex space-x-2 items-center">
          <button
            class="bg-white border-2 border-gray-400 rounded-full px-2 py-2"
          >
            <svg
              class="h-6 w-6 text-gray-500"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table class="min-w-full bg-[#F0F0F0]">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th class="py-2 px-4 border-b">Nombre</th>
              <th class="py-2 px-4 border-b">Email</th>
              <th class="py-2 px-4 border-b">Rol</th>
              <th class="py-2 px-4 border-b">Wallet</th>
              <th class="py-2 px-4 border-b">Status</th>
              <th class="py-2 px-4 border-b">Transacciones</th>
            </tr>
          </thead>
          <tbody class="text-gray-500">
            <tr *ngFor="let user of filteredUsers" class="hover:bg-gray-200">
              <td class="py-2 px-4 border-b">{{ user.name }}</td>
              <td class="py-2 px-4 border-b">{{ user.email }}</td>
              <td class="py-2 px-4 border-b">{{ user.role }}</td>
              <td class="py-2 px-4 border-b">
                <a href="#" class="text-dagblue">Ver</a>
              </td>
              <td class="py-2 px-4 border-b">
                <span class="text-green-500">Active</span>
              </td>
              <td class="py-2 px-4 border-b">
                <button
                  (click)="openTransactionsModal(user)"
                  class="text-blue-500 hover:text-blue-700"
                >
                  Ver Transacciones
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <!-- Botón Anterior -->
          <button
            (click)="previousUserPage()"
            [disabled]="!hasPreviousUserPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Anterior
          </button>
        
          <!-- Números de Página -->
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getUserPageNumbers()"
              (click)="goToUserPage(page)"
              [ngClass]="{
                'bg-[#342C6A] text-white': page === currentUserPage,
                'bg-gray-300 text-gray-800': page !== currentUserPage
              }"
              class="px-4 py-2 rounded-md"
            >
              {{ page }}
            </button>
          </div>
        
          <!-- Botón Siguiente -->
          <button
            (click)="nextUserPage()"
            [disabled]="!hasNextUserPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Siguiente
          </button>
        </div>        
      </div>
    </div>
  </div>
</div>
<!-- Modal para mostrar las transacciones del usuario -->
<div
  *ngIf="showTransactionsModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-5xl w-full max-h-full overflow-y-auto"
  >
    <div class="p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Transacciones de {{ selectedUser?.name }}
        </h3>
        <button
          (click)="closeTransactionsModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <!-- Ícono de cerrar (X) -->
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="overflow-auto">
        <table class="min-w-full divide-y divide-gray-200 table-auto">
          <thead class="bg-darkblue text-white">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Fecha
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Monto
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Descripción
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr *ngFor="let transaction of transactions">
              <td class="px-6 py-4 whitespace-nowrap">
                {{ transaction.date }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ transaction.amount }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ transaction.description }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal para mostrar la lista de envíos pendientes de cancelación -->
<div
  *ngIf="showCancellationModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-2xl font-bold text-gray-900">
          Envíos Pendientes de Cancelación
        </h3>
        <button
          (click)="closeCancellationModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="overflow-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <!-- <select id="filter" class="border-2 border-gray-400 rounded-full px-4 py-2">
              <option>Paquetería</option>
              <option>Fecha</option>
              <option>Costo</option>
              <option>Utilidad</option>
              <option>Precio</option>
              <option>U. licenciat.</option>
              <option>Origen</option>
              <option>Destino</option>
              <option>Estado de pago</option>
              <option>Estado de envío</option>
            </select> -->
            <input
              type="text"
              class="border-2 border-gray-400 rounded-full px-4 py-2"
              placeholder="Buscar..."
            />
          </div>
        </div>
        <table
          class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto"
        >
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th scope="col" class="py-2 px-4 border-b">Usuario</th>
              <th scope="col" class="py-2 px-4 border-b">Email</th>
              <th scope="col" class="py-2 px-4 border-b">Paquetería</th>
              <th scope="col" class="py-2 px-4 border-b">Fecha</th>
              <th scope="col" class="py-2 px-4 border-b">Estado de envío</th>
            </tr>
          </thead>
          <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
            <tr
              *ngFor="let cancellation of cancellations; let i = index"
              class="hover:bg-gray-200"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                {{ cancellation.user_id.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ cancellation.user_id.email }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ cancellation.shipment_id.provider }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ cancellation.createdAt | date : "dd/MM/yyyy HH:mm" }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span
                  [ngClass]="{
                    'text-green-600': cancellation.status === 'Aprobado',
                    'text-yellow-500': cancellation.status === 'Rechazado'
                  }"
                  class="font-bold text-lg"
                >
                  {{ cancellation.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal para reportes de pago -->
<div
  *ngIf="showReporteModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <!-- Título y Cerrar -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Reportes de pago</h2>
        <button
          (click)="closeReporteModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Filtro y Tabla -->
      <div class="overflow-x-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <label for="filter" class="text-gray-700">Filtro</label>
            <select id="filter" class="border rounded px-4 py-2">
              <option>Nombre</option>
            </select>
            <input
              type="text"
              class="border rounded px-4 py-2"
              placeholder="Buscar..."
            />
          </div>
        </div>
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Folio</th>
              <th class="py-2 px-4 border-b">Usuario</th>
              <th class="py-2 px-4 border-b">Cajero</th>
              <th class="py-2 px-4 border-b">Fecha del pago</th>
              <th class="py-2 px-4 border-b">Fecha creación</th>
              <th class="py-2 px-4 border-b">Fecha autorización</th>
              <th class="py-2 px-4 border-b">Monto</th>
              <th class="py-2 px-4 border-b">Estado</th>
              <th class="py-2 px-4 border-b">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let reporte of reportes">
              <td class="py-2 px-4 border-b">{{ reporte.folio }}</td>
              <td class="py-2 px-4 border-b">
                <div class="flex items-center">
                  <img
                    class="h-10 w-10 rounded-full mr-4"
                    [src]="reporte.usuarioImagen"
                    alt="{{ reporte.usuario }}"
                  />
                  <div>{{ reporte.usuario }}</div>
                </div>
              </td>
              <td class="py-2 px-4 border-b">{{ reporte.cajero }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaPago }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaCreacion }}</td>
              <td class="py-2 px-4 border-b">
                {{ reporte.fechaAutorizacion }}
              </td>
              <td class="py-2 px-4 border-b">{{ reporte.monto | currency }}</td>
              <td class="py-2 px-4 border-b">
                <span
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
                >
                  {{ reporte.estado }}
                </span>
              </td>
              <td class="py-2 px-4 border-b">
                <button
                  class="bg-blue-500 text-white px-4 py-2 rounded"
                  (click)="verDetalles(reporte)"
                >
                  Ver Detalles
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal para pedidos de factura -->
<div
  *ngIf="showPedidoModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Rembolso</h2>
        <button
          (click)="closePedidoModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="overflow-x-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <label for="filter" class="text-gray-700">Filtro</label>
            <select id="filter" class="border rounded px-4 py-2">
              <option>Nombre</option>
            </select>
            <input
              type="text"
              class="border rounded px-4 py-2"
              placeholder="Buscar..."
            />
          </div>
        </div>
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Nombre</th>
              <th class="py-2 px-4 border-b">RFC</th>
              <th class="py-2 px-4 border-b">Creacion</th>
              <th class="py-2 px-4 border-b">Actualizacion</th>
              <th class="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <!-- <td class="py-2 px-4 border-b">{{ reporte.folio }}</td>
              <td class="py-2 px-4 border-b">
                <div class="flex items-center">
                  <img class="h-10 w-10 rounded-full mr-4" [src]="reporte.usuarioImagen" alt="{{ reporte.usuario }}">
                  <div>{{ reporte.usuario }}</div>
                </div>
              </td>
              <td class="py-2 px-4 border-b">{{ reporte.cajero }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaPago }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaCreacion }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaAutorizacion }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.monto | currency }}</td>
              <td class="py-2 px-4 border-b">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                  {{ reporte.estado }}
                </span>
              </td>
              <td class="py-2 px-4 border-b">
                <button class="bg-blue-500 text-white px-4 py-2 rounded">Ver Detalles</button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal para envíos -->
<div
  *ngIf="showEnviosModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold text-dagblue">Detalles del Envío</h2>
        <button
          (click)="closeEnviosModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input
          type="text"
          [(ngModel)]="filterName"
          placeholder="Filtrar por nombre o pro ..."
          class="flex-grow border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue mb-2 md:mb-0"
        />
        <div class="flex space-x-2 items-center">
          <div class="flex space-x-2">
            <input
              type="date"
              [(ngModel)]="filterDateFrom"
              placeholder="Desde"
              class="border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue"
            />
            <input
              type="date"
              [(ngModel)]="filterDateTo"
              placeholder="Hasta"
              class="border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue"
            />
            <button
              (click)="applyFilters()"
              class="bg-white border-2 border-gray-400 rounded-full px-2 py-2"
            >
              <svg
                class="h-6 w-6 text-green-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"
                />
                <path
                  d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"
                />
              </svg>
            </button>
            <button
              (click)="exportToCSV()"
              class="bg-gray-300 text-gray-700 px-4 py-2 rounded-full flex items-center justify-center w-full sm:w-auto text-sm sm:text-base"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#217346"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-5 sm:w-6 h-5 sm:h-6 mr-2"
              >
                <path
                  d="M4.5 3.75A1.5 1.5 0 0 1 6 2.25h12a1.5 1.5 0 0 1 1.5 1.5v16.5a1.5 1.5 0 0 1-1.5 1.5H6a1.5 1.5 0 0 1-1.5-1.5V3.75z"
                  fill="#217346"
                />
                <path
                  fill="#fff"
                  d="M8.5 8.25h2.75L12.75 12l-1.5 3.75H8.5L10 12 8.5 8.25zm7 0h1.5v7.5H15.5V8.25z"
                />
              </svg>
              Exportar a CSV
            </button>
          </div>
          
          <!-- Status Dropdown -->
          <select 
            [(ngModel)]="filterStatus" 
            (change)="applyFilters()"
            class="border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue"
          >
            <option value="Guia Generada">Guia generada</option>
            <option value="">Todos los estados</option>
            <option value="Cotizado">Cotizado</option>
            <option value="Cancelado">Cancelado</option>
            <!-- Add other status options -->
          </select>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="w-full text-sm sm:text-base">
          <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th class="px-4 py-2 text-left">Paquetería</th>
              <th class="px-4 py-2 text-left">Licencia</th>
              <th class="px-4 py-2 text-left">Usuario</th>
              <th class="px-4 py-2 text-left">Fecha</th>
              <th class="px-4 py-2 text-left">Precio</th>
              <th class="px-4 py-2 text-left">Costo</th>
              <th class="px-4 py-2 text-left">Utilidad Global</th>
              <th class="px-4 py-2 text-left">Tu Utilidad</th>
              <th class="px-4 py-2 text-left">U. Dagpacket</th>
              <th class="px-4 py-2 text-left">Origen</th>
              <th class="px-4 py-2 text-left">Destino</th>
              <th class="px-4 py-2 text-center">Estado de Pago</th>
              <th class="px-4 py-2 text-center">Estado de Envío</th>
              <th class="px-4 py-2 text-left">Acciones</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 text-gray-500">
            <tr *ngFor="let envio of shipments" class="hover:bg-gray-50">
              <td class="px-4 py-2 uppercase">{{envio.apiProvider === 'Superenvios' ? 'SE': ''}}{{ envio.provider }}</td>
              <td class="px-4 py-2">{{ envio.user_id.name }}{{envio.user_id.email}}</td>
              <td class="px-4 py-2">
                {{ envio.sub_user_id?.name || envio.user_id.name }}{{ envio.sub_user_id?.email || envio.user_id.email }}
              </td>
              <td class="px-4 py-2">
                {{ envio.distribution_at | date : "dd/MM/yyyy/HH:mm" }}
              </td>
              <td class="px-4 py-2">${{ envio.price.$numberDecimal | number:'1.2-2' }}</td>
              <td class="px-4 py-2">${{ envio.cost.$numberDecimal | number:'1.2-2' }}</td>
              <td class="px-4 py-2">${{ envio.dagpacket_profit.$numberDecimal | number:'1.2-2' }}</td>
              <td class="px-4 py-2">${{ envio.utilitie_lic.$numberDecimal | number:'1.2-2' }}</td>
              <td class="px-4 py-2">${{ envio.utilitie_dag.$numberDecimal | number:'1.2-2' }}</td>              
              <td class="px-4 py-2">{{ envio.from.city }},{{envio.from.state}}</td>
              <td class="px-4 py-2">{{ envio.to.city }},{{envio.from.state}}</td>
              <td class="px-4 py-2 text-center">
                <span
                  [ngClass]="{
                    'text-yellow-500': envio.payment.status === 'Pendiente',
                    'text-green-600': envio.payment.status === 'Pagado'
                  }"
                  class="font-bold"
                >
                  {{ envio.payment.status }}
                </span>
              </td>
              <td class="px-4 py-2 text-center font-bold">
                {{ envio.status }}
              </td>
              <td class="px-4 py-2">
                <button
                  (click)="viewDetails(envio.id || envio._id)"
                  class="w-full sm:w-auto bg-dagblue font-bold text-white px-4 py-2 rounded-full hover:bg-blue-900 transition"
                >
                  Ver
                </button>
                <button
                  (click)="openAssignDriverModal(envio.id || envio._id)"
                  class="w-full sm:w-auto bg-green-500 font-bold text-white px-4 py-2 rounded-full hover:bg-green-700 transition mt-2"
                >
                  Asignar repartidor
                </button>
              </td>              

              
            </tr>
          </tbody>
        </table>
        <!-- Paginador -->
        <div class="flex justify-between items-center mt-4">
          <!-- Botón Anterior -->
          <button
            (click)="previousPage()"
            [disabled]="!hasPreviousPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Anterior
          </button>

          <!-- Números de Página -->
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getPageNumbersOfShipment()"
              (click)="goToPageShipment(page)"
              [ngClass]="{
                'bg-[#342C6A] text-white': page === currentPageShipment,
                'bg-gray-300 text-gray-800': page !== currentPageShipment
              }"
              class="px-4 py-2 rounded-md"
            >
              {{ page }}
            </button>
          </div>

          <!-- Botón Siguiente -->
          <button
            (click)="nextPage()"
            [disabled]="!hasNextPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Repartidor en envios-->
<div
  *ngIf="showAssignDriverModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full p-4"
  >
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold text-dagblue">Repartidores</h2>
      <button
        (click)="closeAssignDriverModal()"
        class="text-gray-400 hover:text-gray-500"
      >
        <span class="sr-only">Cerrar</span>
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
      <li
        *ngFor="let user of repartidores"
        class="p-2 hover:bg-gray-100 flex justify-between items-center"
      >
        <button class="bg-orange-500 px-2 py-4"
        (click)="asingDelivery(this.selectShipment,user._id)"
        >
          <p class="font-bold">{{ user.name }}</p>
          <p class="text-sm text-gray-500">{{ user.email }}</p>
        </button>
      </li>
  </div>
</div>



<!-- Modal para empaques-->
<div
  *ngIf="showEmpaques"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-2xl font-bold text-gray-900">Empaques</h3>
        <button
          (click)="closeEmpaquesModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="overflow-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <input
              type="text"
              class="border-2 border-gray-400 rounded-full px-4 py-2"
              placeholder="Buscar..."
            />
          </div>
        </div>
        <table
          class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto"
        >
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th scope="col" class="py-2 px-4 border-b">Licencia</th>
              <th scope="col" class="py-2 px-4 border-b">Usuario</th>
              <th scope="col" class="py-2 px-4 border-b">Fecha Creación</th>
              <th scope="col" class="py-2 px-4 border-b">Tipo de Empaque</th>
              <th scope="col" class="py-2 px-4 border-b">Precio</th>
              <th scope="col" class="py-2 px-4 border-b">Costo</th>
              <th scope="col" class="py-2 px-4 border-b">Utilidad</th>
            </tr>
          </thead>
          <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
            <tr *ngFor="let empaque of empaques" class="hover:bg-gray-200">
              <td class="px-6 py-4 whitespace-nowrap">
                {{ empaque.user_id.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ empaque.sub_user_id?.name || empaque.user_id.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ empaque.createdAt | date : "dd/MM/yyyy HH:mm" }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ empaque.packing.packing_type }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{
                  empaque.packing.packing_id.sell_price?.$numberDecimal ||
                    "0.00"
                }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{
                  empaque.packing.packing_id.cost_price?.$numberDecimal ||
                    "0.00"
                }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{
                  empaque.packing.packing_id.sell_price?.$numberDecimal -
                    empaque.packing.packing_id.cost_price?.$numberDecimal ||
                    "0.00"
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Paginador de Empaques -->
        <div class="flex justify-between items-center mt-4">
          <!-- Botón Anterior -->
          <button
            (click)="previousPackagePage()"
            [disabled]="!hasPreviousPackagePage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Anterior
          </button>

          <!-- Números de Página -->
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getPackagePageNumbers()"
              (click)="goToPackagePage(page)"
              [ngClass]="{
                'bg-[#342C6A] text-white': page === currentPackagePage,
                'bg-gray-300 text-gray-800': page !== currentPackagePage
              }"
              class="px-4 py-2 rounded-md"
            >
              {{ page }}
            </button>
          </div>

          <!-- Botón Siguiente -->
          <button
            (click)="nextPackagePage()"
            [disabled]="!hasNextPackagePage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para pago servicios S-->
<div
  *ngIf="showPagoS"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Pagos De Servicios</h2>
        <button
          (click)="closePagosSModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input
          type="text"
          placeholder="Filtrar por nombre o pro ..."
          class="border rounded px-4 py-2 mb-2 md:mb-0"
        />
        <div class="flex space-x-2 items-center">
          <div class="flex space-x-2">
            <input
              type="date"
              placeholder="Desde"
              class="border rounded px-4 py-2"
            />
            <input
              type="date"
              placeholder="Hasta"
              class="border rounded px-4 py-2"
            />
            <button class="bg-white border rounded px-2 py-2">
              <svg
                class="h-6 w-6 text-green-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"
                />
                <path
                  d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"
                />
              </svg>
            </button>
          </div>
          <button class="bg-white border rounded px-2 py-2">
            <svg
              class="h-6 w-6 text-gray-500"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th class="py-2 px-4 border-b">Usuario</th>
              <th class="py-2 px-4 border-b">Servicio</th>
              <th class="py-2 px-4 border-b">Fecha Pago</th>
              <th class="py-2 px-4 border-b">Num. Referencia</th>
              <th class="py-2 px-4 border-b">Num. Autorizacion</th>
              <th class="py-2 px-4 border-b">Monto</th>
              <th class="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
            <tr *ngFor="let transaction of transactions" class="hover:bg-gray-200">
              <td class="py-2 px-4 border-b">{{ transaction.user_id.name }}</td>
              <td class="py-2 px-4 border-b">{{ transaction.service }}</td>
              <td class="py-2 px-4 border-b">{{ transaction.transaction_date | date : 'dd/MM/yyyy HH:mm' }}</td>
              <td class="py-2 px-4 border-b">{{ transaction.transaction_number }}</td>
              <td class="py-2 px-4 border-b">{{ transaction.authorization_number || 'N/A' }}</td>
              <td class="py-2 px-4 border-b">{{ transaction.amount?.$numberDecimal || '0.00' }}</td>
              <td class="py-2 px-4 border-b">
                <span
                  [ngClass]="{
                    'text-green-600': transaction.status === 'Pagado',
                    'text-yellow-500': transaction.status === 'Pendiente',
                    'text-red-500': transaction.status === 'Cancelado'
                  }"
                  class="font-bold text-lg"
                >
                  {{ transaction.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>        
        <!-- Paginador -->
        <div class="flex justify-between items-center mt-4">
          <!-- Botón Anterior -->
          <button
            (click)="previousPage()"
            [disabled]="!hasPreviousPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Anterior
          </button>

          <!-- Números de Página -->
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getPageNumbers()"
              (click)="goToPage(page)"
              [ngClass]="{
                'bg-[#342C6A] text-white': page === currentPageTransaction,
                'bg-gray-300 text-gray-800': page !== currentPageTransaction
              }"
              class="px-4 py-2 rounded-md"
            >
              {{ page }}
            </button>
          </div>

          <!-- Botón Siguiente -->
          <button
            (click)="nextPage()"
            [disabled]="!hasNextPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para pago servicios R-->
<div
  *ngIf="showPagoR"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="p-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-2xl font-bold text-gray-900">Pagos De Recargas</h3>
        <button
          (click)="closePagoRModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="overflow-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <input
              type="text"
              class="border-2 border-gray-400 rounded-full px-4 py-2"
              placeholder="Buscar..."
            />
          </div>
        </div>
        <table
          class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto"
        >
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th scope="col" class="py-2 px-4 border-b">ID</th>
              <th scope="col" class="py-2 px-4 border-b">Servicio</th>
              <th scope="col" class="py-2 px-4 border-b">Fecha Pago</th>
              <th scope="col" class="py-2 px-4 border-b">Num. Referencia</th>
              <th scope="col" class="py-2 px-4 border-b">Método de Pago</th>
              <th scope="col" class="py-2 px-4 border-b">Monto</th>
              <th scope="col" class="py-2 px-4 border-b">Estado</th>
            </tr>
          </thead>
          <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
            <tr
              *ngFor="let transaction of transactions; let i = index"
              class="hover:bg-gray-200"
            >
              <td class="px-6 py-4 whitespace-nowrap">{{ transaction._id }}</td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ transaction.details }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ transaction.transaction_date | date : "dd/MM/yyyy HH:mm" }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ transaction.transaction_number }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ transaction.payment_method }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ transaction.amount?.$numberDecimal || "0.00" }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span
                  [ngClass]="{
                    'text-green-600': transaction.status === 'Pagado',
                    'text-yellow-500': transaction.status === 'Pendiente',
                    'text-red-500': transaction.status === 'Cancelado'
                  }"
                  class="font-bold text-lg"
                >
                  {{ transaction.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Paginador de Recargas -->
        <div class="flex justify-between items-center mt-4">
          <!-- Botón Anterior -->
          <button
            (click)="previousRechargePage()"
            [disabled]="!hasPreviousRechargePage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Anterior
          </button>

          <!-- Números de Página -->
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getRechargePageNumbers()"
              (click)="goToRechargePage(page)"
              [ngClass]="{
                'bg-[#342C6A] text-white': page === currentRechargePage,
                'bg-gray-300 text-gray-800': page !== currentRechargePage
              }"
              class="px-4 py-2 rounded-md"
            >
              {{ page }}
            </button>
          </div>

          <!-- Botón Siguiente -->
          <button
            (click)="nextRechargePage()"
            [disabled]="!hasNextRechargePage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para reparto-->
<div
  *ngIf="showRepartoM"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Reparto Multimarca</h2>
        <button
          (click)="closeRepartoMModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input
          type="text"
          placeholder="Filtrar por nombre o pro ..."
          class="border rounded px-4 py-2 mb-2 md:mb-0"
        />
        <div class="flex space-x-2 items-center">
          <div class="flex space-x-2">
            <input
              type="date"
              placeholder="Desde"
              class="border rounded px-4 py-2"
            />
            <input
              type="date"
              placeholder="Hasta"
              class="border rounded px-4 py-2"
            />
            <button class="bg-white border rounded px-2 py-2">
              <svg
                class="h-6 w-6 text-green-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"
                />
                <path
                  d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"
                />
              </svg>
            </button>
          </div>
          <button class="bg-white border rounded px-2 py-2">
            <svg
              class="h-6 w-6 text-gray-500"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">#</th>
              <th class="py-2 px-4 border-b">Fecha</th>
              <th class="py-2 px-4 border-b">Licenciatario</th>
              <th class="py-2 px-4 border-b">Usuario</th>
              <th class="py-2 px-4 border-b">Nombre Remitente</th>
              <th class="py-2 px-4 border-b">Telefono</th>
              <th class="py-2 px-4 border-b">Status</th>
              <th class="py-2 px-4 border-b">Recibo Pdf</th>
              <th class="py-2 px-4 border-b">Paqueteria</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr>
              <td class="py-2 px-4 border-b">Ejemplo Nombre</td>
              <td class="py-2 px-4 border-b">ABC123456</td>
              <td class="py-2 px-4 border-b">01/01/2024</td>
              <td class="py-2 px-4 border-b">15/01/2024</td>
              <td class="py-2 px-4 border-b">Activo</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal para cupones-->
<div
  *ngIf="showCupones"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Registro de cupones</h2>
        <button
          (click)="closeCuponesModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Sección de búsqueda y botón "Crear un cupón" -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <div class="flex space-x-2 items-center">
          <button
            (click)="openCrearCuponModal()"
            class="bg-darkblue text-white px-4 py-2 rounded hover:bg-darkblue-light transition-all duration-300"
          >
            Crear un cupón
          </button>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table class="min-w-full bg-[#F0F0F0] divide-y divide-gray-200 table-auto">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th class="py-2 px-4 border-b">Cupon</th>
              <th class="py-2 px-4 border-b">Licenciatario</th>
              <th class="py-2 px-4 border-b">Fecha</th>
              <th class="py-2 px-4 border-b">Tipo</th>
              <th class="py-2 px-4 border-b">Valor</th>
              <th class="py-2 px-4 border-b">Disponibilidad</th>
              <th class="py-2 px-4 border-b">Usos</th>
              <th class="py-2 px-4 border-b">Límite de usos</th>
            </tr>
          </thead>
          <tbody class="bg-[#F0F0F0] divide-y divide-gray-200 text-gray-500">
            <tr *ngFor="let coupon of cupons" class="hover:bg-gray-200">
              <td class="py-2 px-4 border-b text-center">{{ coupon.code }}</td>
              <td class="py-2 px-4 border-b">{{ coupon.user_id.name }}</td>
              <td class="py-2 px-4 border-b">
                {{ coupon.start_date | date : 'dd/MM/yyyy' }} -
                {{ coupon.end_date | date : 'dd/MM/yyyy' }}
              </td>
              <td class="py-2 px-4 border-b">{{ coupon.type }}</td>
              <td class="py-2 px-4 border-b">
                {{ coupon.value }}{{ coupon.type_value === 'Porcentaje' ? '%' : '' }}
              </td>
              <td class="py-2 px-4 border-b">
                {{ coupon.is_unlimited ? 'Ilimitado' : 'Limitado' }}
              </td>
              <td class="py-2 px-4 border-b">
                {{ coupon.is_unlimited ? 'N/A' : coupon.quantity || 0 }}
              </td>
              <td class="py-2 px-4 border-b">
                {{ coupon.status ? 'Activo' : 'Inactivo' }}
              </td>
            </tr>
          </tbody>
        </table>        
        <!-- Paginador de Cupones -->
        <div class="flex justify-between items-center mt-4">
          <!-- Botón Anterior -->
          <button
            (click)="previousCouponPage()"
            [disabled]="!hasPreviousCouponPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Anterior
          </button>

          <!-- Números de Página -->
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getCouponPageNumbers()"
              (click)="goToCouponPage(page)"
              [ngClass]="{
                'bg-[#342C6A] text-white': page === currentCouponPage,
                'bg-gray-300 text-gray-800': page !== currentCouponPage
              }"
              class="px-4 py-2 rounded-md"
            >
              {{ page }}
            </button>
          </div>

          <!-- Botón Siguiente -->
          <button
            (click)="nextCouponPage()"
            [disabled]="!hasNextCouponPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal para Crear Cupón -->
<div
  *ngIf="showCrearCuponModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Crear cupón</h2>
        <button
          (click)="closeCrearCuponModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <!-- Buscador -->
      <div class="mb-4">
        <input
          [(ngModel)]="searchTerm"
          (input)="onSearch()"
          type="text"
          placeholder="Buscar usuario..."
          class="border rounded px-4 py-2 w-full"
        />
      </div>
      <!-- Grid de Usuarios -->
      <div class="grid grid-cols-2 gap-4">
        <div
          *ngFor="let user of filteredUsers"
          class="flex items-center border rounded-lg p-2 cursor-pointer hover:bg-gray-100"
          (click)="selectUser(user)"
        >
          <img
            *ngIf="user.image"
            [src]="user.image"
            alt="{{ user.name }}"
            class="h-10 w-10 rounded-full object-cover mr-3"
          />
          <img
            *ngIf="!user.image"
            src="https://via.placeholder.com/40"
            alt="Placeholder"
            class="h-10 w-10 rounded-full object-cover mr-3"
          />
          <div class="text-sm">
            <p class="font-medium">{{ user.name }}</p>
            <p class="text-gray-500 text-xs">{{ user.email }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal de configuración del cupón -->
<div
  *ngIf="showCuponConfigModal"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full"
  >
    <div class="container mx-auto p-6">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Crear cupón</h2>
      </div>
      <!-- Información del usuario seleccionado -->
      <div
        class="flex items-center border rounded-lg p-2 mb-4 shadow-sm bg-gray-50"
      >
        <img
          *ngIf="userForCupon?.image"
          [src]="userForCupon.image"
          alt="{{ userForCupon.name }}"
          class="h-10 w-10 rounded-full object-cover mr-3"
        />
        <div class="flex-grow">
          <p class="font-medium text-sm text-gray-800">
            {{ userForCupon?.name }}
          </p>
          <p class="text-gray-500 text-xs">{{ userForCupon?.email }}</p>
        </div>
        <button
          (click)="clearUserForCupon()"
          class="text-gray-500 hover:text-red-500 ml-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="h-4 w-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Campos del formulario -->
      <form #cuponForm="ngForm" (ngSubmit)="createCupon(cuponForm)">
        <!-- Código del cupón -->
        <input
          type="text"
          name="code"
          [(ngModel)]="cuponData.code"
          placeholder="Código del cupón"
          class="border rounded px-4 py-2 w-full mb-4"
          required
        />

        <!-- Tipo de cupón -->
        <div class="mb-4">
          <label class="block font-medium mb-2">Tipo cupón:</label>
          <select
            name="type"
            [(ngModel)]="cuponData.type"
            class="border rounded px-4 py-2 w-full"
            required
          >
            <option selected value="Cupon Dagpacket">Cupon Dagpacket</option>
            <option value="Cupon Licenciatario">Cupon Licenciatario</option>
            <option value="Cupon Compuesto">Cupon Compuesto</option>
          </select>
        </div>

        <!-- Tipo de valor y valor -->
        <div class="mb-4">
          <label class="block font-medium mb-2">Tipo valor:</label>
          <div class="flex items-center">
            <select
              name="valueType"
              [(ngModel)]="cuponData.type_value"
              class="border rounded px-4 py-2 mr-2"
              required
            >
              <option value="Numero">Número</option>
              <option value="Porcentaje">Porcentaje</option>
            </select>
            <input
              type="number"
              name="value"
              [(ngModel)]="cuponData.value"
              placeholder="Ingrese valor"
              class="border rounded px-4 py-2 w-full"
              required
            />
            <span class="ml-2">MXN</span>
          </div>
        </div>

        <!-- Limitadores -->
        <div class="mb-4">
          <label class="block font-medium mb-2">Limitadores:</label>
          <div class="mb-2">
            <label class="flex items-center">
              <input
                type="checkbox"
                name="isUnlimited"
                [(ngModel)]="cuponData.is_unlimited"
                class="mr-2"
              />
              Uso ilimitado
            </label>
            <input
              type="number"
              name="quantity"
              [(ngModel)]="cuponData.quantity"
              placeholder="Usos*"
              class="border rounded px-4 py-2 w-full"
              [disabled]="cuponData.is_unlimited"
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <input
              type="date"
              name="startDate"
              [(ngModel)]="cuponData.start_date"
              class="border rounded px-4 py-2"
            />
            <input
              type="date"
              name="endDate"
              [(ngModel)]="cuponData.end_date"
              class="border rounded px-4 py-2"
            />
          </div>
        </div>

        <!-- Descripción -->
        <div class="mb-4">
          <label class="block font-medium mb-2">Descripción:</label>
          <input
            type="text"
            name="description"
            [(ngModel)]="cuponData.description"
            placeholder="Descripción del cupón"
            class="border rounded px-4 py-2 w-full"
          />
        </div>

        <!-- Botón para enviar -->
        <button
          type="submit"
          class="bg-darkblue text-white w-full py-2 rounded hover:bg-orange-600"
        >
          Crear Cupón
        </button>
      </form>

      <!-- Botón de creación -->
      <div class="flex justify-end mt-4">
        <button
          (click)="goBackToUserSelection()"
          class="flex items-center text-gray-500 hover:text-gray-800 px-4 py-2 rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="h-5 w-5 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          Atrás
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal para Direcciones-->
<div
  *ngIf="showDirecciones"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold text-dagblue">Lista de Direcciones</h2>
        <button
          (click)="closeDireccionesModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input
          type="text"
          placeholder="Filtrar por nombre o pro ..."
          class="flex-grow border-2 border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-dagblue mb-2 md:mb-0"
        />
        <div class="flex space-x-2 items-center">
          <button
            class="bg-white border-2 border-gray-400 rounded-full px-2 py-2"
          >
            <svg
              class="h-6 w-6 text-gray-500"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table class="min-w-full bg-[#F0F0F0]">
          <thead class="bg-[#F0F0F0] text-dagblue font-bold">
            <tr>
              <th class="py-2 px-4 border-b">C.P</th>
              <th class="py-2 px-4 border-b">Nombre</th>
              <th class="py-2 px-4 border-b">Nacional</th>
              <th class="py-2 px-4 border-b">Pais</th>
              <th class="py-2 px-4 border-b">Dirección</th>
              <th class="py-2 px-4 border-b">Usuario</th>
            </tr>
          </thead>
          <tbody class="text-gray-500">
            <tr *ngFor="let address of addresses" class="hover:bg-gray-200">
              <td class="py-2 px-4 border-b">{{ address.zip_code }}</td>
              <td class="py-2 px-4 border-b">{{ address.name }}</td>
              <td class="py-2 px-4 border-b">{{ address.settlement }}</td>
              <td class="py-2 px-4 border-b">{{ address.country }}</td>
              <td class="py-2 px-4 border-b">
                {{ address.street }}, {{ address.city }}, {{ address.state }}
              </td>
              <td class="py-2 px-4 border-b">{{ address.user }}</td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between items-center mt-4">
          <!-- Botón Anterior -->
          <button
            (click)="previousAddressPage()"
            [disabled]="!hasPreviousAddressPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Anterior
          </button>
        
          <!-- Números de Página -->
          <div class="flex space-x-1">
            <button
              *ngFor="let page of getAddressPageNumbers()"
              (click)="goToAddressPage(page)"
              [ngClass]="{
                'bg-[#342C6A] text-white': page === currentAddressPage,
                'bg-gray-300 text-gray-800': page !== currentAddressPage
              }"
              class="px-4 py-2 rounded-md"
            >
              {{ page }}
            </button>
          </div>
          
        
          <!-- Botón Siguiente -->
          <button
            (click)="nextAddressPage()"
            [disabled]="!hasNextAddressPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            Siguiente
          </button>
        </div>        
      </div>
    </div>
  </div>
</div>
<!-- Modal para Recotizciones-->
<div
  *ngIf="showRecotizciones"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
>
  <div
    class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto"
  >
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Recotizaciones</h2>
        <button
          (click)="closeRecotizcionesModal()"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">Cerrar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Sección de búsqueda y filtros -->
      <div class="mb-4 flex flex-wrap justify-between items-center">
        <input
          type="text"
          placeholder="Buscar Usuario..."
          class="border rounded px-4 py-2 mb-2 md:mb-0"
        />
        <div class="flex space-x-2 items-center">
          <div class="flex space-x-2">
            <button class="bg-white border rounded px-2 py-2">
              <svg
                class="h-6 w-6 text-green-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19.707 5.293l-14 14a1 1 0 01-1.414-1.414l14-14a1 1 0 011.414 1.414z"
                />
                <path
                  d="M7 10h4a1 1 0 010 2H7a1 1 0 010-2zm-1 5h6a1 1 0 010 2H6a1 1 0 010-2z"
                />
              </svg>
            </button>
          </div>
          <button class="bg-white border rounded px-2 py-2">
            <svg
              class="h-6 w-6 text-gray-500"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M3 6a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 010 2H4a1 1 0 01-1-1z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Fecha Creacion</th>
              <th class="py-2 px-4 border-b">Envio</th>
              <th class="py-2 px-4 border-b">Precio a Completar</th>
              <th class="py-2 px-4 border-b">Estado</th>
              >
            </tr>
          </thead>
          <tbody>
            <!-- <tr>
              <td class="py-2 px-4 border-b">Ejemplo Nombre</td>
              <td class="py-2 px-4 border-b">ABC123456</td>
              <td class="py-2 px-4 border-b">01/01/2024</td>
              <td class="py-2 px-4 border-b">15/01/2024</td>
              <td class="py-2 px-4 border-b">Activo</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal para rembolsos por clip -->
<div *ngIf="showRembolsoModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-7xl w-full max-h-full overflow-y-auto">
    <div class="container mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">Rembolso</h2>
        <button (click)="closeRembolsoModal()" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Cerrar</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="overflow-x-auto">
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-4">
            <label for="filter" class="text-gray-700">Filtro</label>
            <select id="filter" class="border rounded px-4 py-2">
              <option>Nombre</option>
            </select>
            <input type="text" class="border rounded px-4 py-2" placeholder="Buscar..." />
          </div>
        </div>
        <table class="min-w-full bg-white">
          <thead class="bg-darkblue text-white">
            <tr>
              <th class="py-2 px-4 border-b">Licenciatario</th>
              <th class="py-2 px-4 border-b">Item</th>
              <th class="py-2 px-4 border-b">Fecha</th>
              <th class="py-2 px-4 border-b">Monto</th>
              <th class="py-2 px-4 border-b">Referencia</th>
              <th class="py-2 px-4 border-b">Servicio</th>
              <th class="py-2 px-4 border-b">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <!-- <td class="py-2 px-4 border-b">{{ reporte.folio }}</td>
              <td class="py-2 px-4 border-b">
                <div class="flex items-center">
                  <img class="h-10 w-10 rounded-full mr-4" [src]="reporte.usuarioImagen" alt="{{ reporte.usuario }}">
                  <div>{{ reporte.usuario }}</div>
                </div>
              </td>
              <td class="py-2 px-4 border-b">{{ reporte.cajero }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaPago }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaCreacion }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.fechaAutorizacion }}</td>
              <td class="py-2 px-4 border-b">{{ reporte.monto | currency }}</td>
              <td class="py-2 px-4 border-b">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                  {{ reporte.estado }}
                </span>
              </td>
              <td class="py-2 px-4 border-b">
                <button class="bg-blue-500 text-white px-4 py-2 rounded">Ver Detalles</button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>