import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { AuthLoginService } from '../users/auth-login.service';

@Injectable({
  providedIn: 'root'
})
export class CancellationService {

  constructor(
    private authService: AuthLoginService,
    private http: HttpClient
  ) { }

  createCancellationRequest(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/cancellations/request', data);
  }

  getUserCancellations(page: number = 1, limit: number = 10, sortBy: string = 'requested_at', sortOrder: 'asc' | 'desc' = 'desc'): Observable<any> {
    const userId = this.authService.getId();
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);

    return this.http.get(`${environment.apiUrl}/cancellations/${userId}`, { params });
  }


  getPendingCancellations(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cancellations/all/pending/count`);
  }


  getAllPendingCancellations(
    page: number = 1, 
    limit: number = 10, 
    sortBy: string = 'requested_at', 
    sortOrder: 'asc' | 'desc' = 'desc'
  ): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
 
    return this.http.get(`${environment.apiUrl}/cancellations/all/pending`, { params });
  }

  getAllCancellations(page: number = 1, limit: number = 10, sortBy: string = 'requested_at', sortOrder: 'asc' | 'desc' = 'desc', search: string = ''): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder);
    
    if (search) {
      params = params.set('search', search);
    }

    return this.http.get(`${environment.apiUrl}/cancellations/all`, { params });
  }

  updateCancellationRequest(id: string, data: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/cancellations/${id}`, data);
  }

  getCancellationById(id: string): Observable<any> {
    console.log('Getting cancellation request:', id);
    return this.http.get(`${environment.apiUrl}/cancellations/read/${id}`);
  }
}
