import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class EmiausService {
  private baseUrl = `${environment.apiUrl}/emida`;

  constructor(private http: HttpClient) {}

  /**
   * Obtener el balance para recargas.
   */
  getRechargeBalance(): Observable<any> {
    const url = `${this.baseUrl}/account-balance?type=recharge`;
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  /**
   * Obtener el balance para pagos.
   */
  getPaymentBalance(): Observable<any> {
    const url = `${this.baseUrl}/account-balance?type=payment`;
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  /**
   * Manejo de errores en las solicitudes HTTP.
   * @param error - Error generado por la solicitud HTTP.
   * @returns Observable que arroja un error manejado.
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('Error en la solicitud:', error);
    return throwError(() =>
      new Error('Ocurrió un error en la solicitud. Por favor, intenta de nuevo más tarde.')
    );
  }
}
