import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentsService } from 'src/app/_services/shipments/shipments.service';
import { AuthLoginService } from 'src/app/_services/users/auth-login.service';

@Component({
  selector: 'app-shipment-status',
  templateUrl: './shipment-status.component.html',
  styleUrls: ['./shipment-status.component.scss'],
})
export class ShipmentStatusComponent implements OnInit {
  packages: any[] = [];
  filteredPackages: any[] = []; // Paquetes filtrados
  currentPage: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;
  Math: any = Math;
  sortBy: string = 'distribution_at';
  sortOrder: 'asc' | 'desc' = 'desc';
  role: string = '';
  startDate: string = '';
  endDate: string = '';
  statusFilter: string = '';

  constructor(
    private shipmentsService: ShipmentsService,
    private authService: AuthLoginService,
    private router: Router
  ) {
    this.Math = Math;
  }

  ngOnInit(): void {
    this.loadShipments();
    this.getUserProfile();
  }

  getUserProfile() {
    this.authService.userProfile().subscribe(
      (response) => {
        console.log('datos del usuario', response);
        this.role = response.data.role;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  verifyRole(): boolean {
    if (this.role === 'CAJERO') {
      return true;
    }
    return false;
  }

  loadShipments() {
    this.shipmentsService
      .getUserShipments(
        this.currentPage,
        this.pageSize,
        this.sortBy,
        this.sortOrder
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.packages = response.data.shipments;
            this.applyFilters(); // Aplicar filtros después de cargar los datos
            this.totalItems = response.data.totalShipments;
            this.totalPages = response.data.totalPages;
          } else {
            console.error('Error loading shipments:', response.message);
          }
        },
        (error) => console.error('Error', error)
      );
  }

  applyFilters() {
    const start = this.startDate ? new Date(this.startDate) : null;
    const end = this.endDate ? new Date(this.endDate) : null;
  
    this.filteredPackages = this.packages.filter((envio) => {
      const distributionDate = new Date(envio.distribution_at);
  
      // Filtro por rango de fechas
      const isWithinDateRange =
        (!start || distributionDate >= start) &&
        (!end || distributionDate <= end);
  
      // Filtro por estado en `envio.payment.status` o `envio.status`
      const matchesStatus =
        !this.statusFilter ||
        envio.payment.status === this.statusFilter ||
        envio.status === this.statusFilter;
  
      return isWithinDateRange && matchesStatus;
    });
  
    // Verificar si `filteredPackages` tiene datos después del filtrado
    console.log('Paquetes filtrados:', this.filteredPackages);
  }
  

  clearDateFilter(): void {
    this.startDate = '';
    this.endDate = '';
    this.statusFilter = '';
    this.applyFilters();
  }

  exportToCSV(): void {
    if (this.filteredPackages.length === 0) {
      console.warn('No hay datos para exportar');
      return;
    }

    // Encabezados del archivo CSV
    const headers = [
      'Paquetería',
      'Fecha',
      'Costo',
      'Utilidad Global',
      'Precio',
      'Tu utilidad',
      'U. Dagpacket',
      'Origen',
      'Destino',
      'Estado de Pago',
      'Estado de Envío',
    ];

    // Formatear los datos
    const csvData = this.filteredPackages.map((envio) => [
      envio.provider,
      envio.distribution_at
        ? new Date(envio.distribution_at).toLocaleDateString()
        : '',
      envio.cost?.$numberDecimal || '',
      envio.dagpacket_profit?.$numberDecimal || '',
      envio.price?.$numberDecimal || '',
      envio.utilitie_lic?.$numberDecimal || '',
      envio.utilitie_dag?.$numberDecimal || '',
      envio.from.city || '',
      envio.to.city || '',
      envio.payment.status || '',
      envio.status || '',
    ]);

    // Crear el contenido CSV
    const csvContent = [
      headers.join(','), // Agregar encabezados
      ...csvData.map((row) => row.join(',')), // Agregar filas de datos
    ].join('\n');

    // Crear un blob con el contenido CSV y descargar el archivo
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Historial_Envios_${new Date().toLocaleDateString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadShipments();
    }
  }

  changeSort(column: string) {
    if (this.sortBy === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = column;
      this.sortOrder = 'asc';
    }
    this.currentPage = 1;
    this.loadShipments();
  }

  getPaymentStatusClass(status: string): string {
    switch (status) {
      case 'Pagado':
        return 'bg-green-500';
      case 'Pendiente':
        return 'bg-orange-500';
      case 'Cancelado':
        return 'bg-red-600';
      case 'Reembolsado':
        return 'bg-blue-500';
      default:
        return 'bg-gray-500';
    }
  }

  getStatus(status: string): string {
    switch (status) {
      case 'En recolección':
        return 'bg-blue-500';
      case 'En reparto':
        return 'bg-orange-500';
      case 'Cancelado':
        return 'bg-red-600';
      default:
        return 'bg-gray-500';
    }
  }

  viewDetails(shipmentId: string) {
    console.log('Navigating to details for shipment:', shipmentId);
    this.router.navigate(['/shipment-details', shipmentId]);
  }

  updateStartDate(event: Event) {
    this.startDate = (event.target as HTMLInputElement).value;
  }

  updateEndDate(event: Event) {
    this.endDate = (event.target as HTMLInputElement).value;
  }

  updateStatusFilter(event: Event) {
    this.statusFilter = (event.target as HTMLSelectElement).value;
  }
}