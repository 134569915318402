import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; // Importa HttpClient
import { Observable } from 'rxjs'; // Importa Observable para manejar la respuesta

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  // URL de la API
  private apiUrl = 'http://localhost:3000/api/v1/users/delivery-users';

  constructor(private http: HttpClient) { } // Inyecta HttpClient

  // Método para obtener los usuarios delivery
  getDeliveryUsers(): Observable<any> {
    return this.http.get(this.apiUrl); // Realiza la petición GET
  }
}
