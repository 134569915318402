<nav class="navbar fixed top-0 z-40 w-full bg-white">
  <div
    class="px-3 py-3 lg:px-5 lg:pl-3 flex justify-between items-center relative overflow-hidden"
  >
    <!-- Moto animada -->
    <div class="vehicle-container relative z-10">
      <img
        src="../../../assets/images/Moto_animacion.png"
        alt="Moto"
        class="vehicle-image moto"
      />
      <img
        src="../../../assets/images/Camion_animacion.png"
        alt="Camión"
        class="vehicle-image camion"
      />
      <img
        src="../../../assets/images/Avion_animacion.png"
        alt="Avión"
        class="vehicle-image avion"
      />
    </div>

    <!-- Botón de hamburguesa -->
    <button class="hamburger-button" (click)="toggleSidebar()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 5.25h16.5M3.75 12h16.5m-16.5 6.75h16.5"
        />
      </svg>
    </button>

    <!-- Botones de acción (Carrito, Notificaciones, Cerrar sesión) -->
    <div class="flex items-center gap-4 z-20 relative">
      <!-- Botón de carrito -->
      <button
        (click)="toggleCart()"
        class="nav-icon p-2 hover:bg-dagblue hover:text-white rounded-full relative"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
          />
        </svg>
        <span
          *ngIf="cartItems.length > 0"
          class="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center"
        >
          {{ cartItems.length }}
        </span>
      </button>

      <!-- Botón de notificaciones -->
      <button
        (click)="openNotificationModal()"
        class="nav-icon p-2 hover:bg-dagblue hover:text-white rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 17h5l-1.405-1.405A7.956 7.956 0 0017.787 11.5C17.787 7.477 14.31 4 10 4S2.213 7.477 2.213 11.5a7.956 7.956 0 001.192 4.05L2 17h5m6 0v.5a2.5 2.5 0 11-5 0V17m6 0H9"
          />
        </svg>
      </button>

      <!-- Botón de cerrar sesión -->
      <button
        (click)="logout()"
        class="nav-item flex items-center rounded-lg p-2 hover:bg-dagblue hover:text-white duration-200 gap-2 text-dagblue"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            fill-rule="evenodd"
            d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6ZM5.78 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-1.72-1.72H15a.75.75 0 0 0 0-1.5H4.06l1.72-1.72a.75.75 0 0 0 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Salir</span>
      </button>
    </div>
  </div>
</nav>

<div
  *ngIf="isCartOpen"
  class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
>
  <div
    class="bg-white rounded-xl shadow-2xl w-full max-w-lg mx-4 overflow-hidden"
  >
    <div
      class="bg-dagblue text-white py-4 px-6 flex justify-between items-center"
    >
      <h2 class="text-2xl font-bold">Carrito de Compras</h2>
      <button
        (click)="closeCart()"
        class="text-white hover:text-gray-300 transition duration-300"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
    </div>
    <div class="p-6">
      <div
        *ngIf="cartItems.length === 0"
        class="text-gray-600 text-center py-8"
      >
        <svg
          class="w-16 h-16 mx-auto mb-4 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          ></path>
        </svg>
        <p class="font-medium">No hay envíos pendientes en el carrito.</p>
      </div>
      <ul
        *ngIf="cartItems.length > 0"
        class="space-y-4 max-h-64 overflow-y-auto"
      >
        <li
          *ngFor="let item of cartItems"
          class="bg-gray-50 rounded-lg overflow-hidden"
        >
          <div class="p-4">
            <div class="flex justify-between items-center mb-2">
              <span class="font-medium text-dagblue"
                >Envío: {{ item.provider }}</span
              >
              <button
                class="text-red-500 hover:text-red-700"
                (click)="removeItem(item)"
              >
                <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path>
                </svg>
              </button>
            </div>
            <div class="text-sm text-gray-600">
              <p>Origen: {{ item.from.city || "No especificado" }}</p>
              <p>Destino: {{ item.to.city || "No especificado" }}</p>
            </div>
          </div>
          <div class="bg-gray-100 px-4 py-2 flex justify-between items-center">
            <span class="text-sm font-bold">ID de envío: {{ item._id }}</span>
            <span class="font-medium"
              >${{ getItemPrice(item) | number : "1.2-2" }}</span
            >
          </div>
        </li>
      </ul>
    </div>
    <div class="bg-gray-100 px-6 py-4">
      <div
        *ngIf="cartItems.length > 0"
        class="flex justify-between items-center mb-4"
      >
        <span class="font-bold text-lg">Total:</span>
        <span class="font-bold text-lg text-dagblue"
          >${{ getTotalPrice() | number : "1.2-2" }}</span
        >
      </div>
      <div class="flex justify-between items-center">
        <button
          (click)="closeCart()"
          class="bg-gray-300 rounded-full hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 transition duration-300"
        >
          Cerrar
        </button>
        <button
          *ngIf="cartItems.length > 0"
          [routerLink]="['/pay-shipments']"
          class="bg-dagpk hover:bg-dagpk-dark text-white font-bold py-2 px-4 rounded-full transition duration-300"
        >
          Ir a pagar
        </button>
      </div>
    </div>
  </div>
</div>

<aside
  id="logo-sidebar"
  class="fixed top-0 left-0 z-40 w-64 h-screen overflow-y-scroll pt-4 transition-transform bg-dagblue text-white sm:translate-x-0 rounded-tr-[30px] rounded-br-[30px] pr-0"
  [ngClass]="{
    '-translate-x-full': !isSidebarOpen,
    'translate-x-0': isSidebarOpen
  }"
  aria-label="Sidebar"
>
  <div class="w-full h-auto p-3">
    <!-- Logotipo en la parte superior -->
    <div class="text-center mb-6">
      <a [routerLink]="['/landing']">
        <img
          src="../../../assets/images/LogotipoM.png"
          alt="Logotipo"
          class="mx-auto"
          style="max-width: 180px; height: auto"
        />
      </a>
    </div>
    <a
    [routerLink]="['/settings']"
    class="py-3 rounded-lg text-center">
      <img
        *ngIf="user?.image; else defaultAvatar"
        [src]="user.image"
        alt="User Avatar"
        class="rounded-full w-24 h-24 mx-auto"
      />
      <ng-template #defaultAvatar>
        <img
          src="../../../assets/images/avatar-placeholder.png"
          alt="User Avatar"
          class="rounded-full w-24 h-24 mx-auto"
        />
      </ng-template>
      <div *ngIf="user" class="user-name mt-3 text-lg font-semibold">
        {{ user.name }} {{ user.surname }}
      </div>
      <div 
      *ngIf="user" class="user-name mt-3 text-lg font-semibold">
        {{ user.role }}
      </div>
      <div *ngIf="hasRole('ADMIN')" class="balance mt-2 text-sm text-white">
        <strong>Balance de Recargas: </strong>${{ rechargeBalance || 'Cargando...' }}
      </div>
      <div *ngIf="hasRole('ADMIN')" class="balance mt-2 text-sm text-white">
        <strong>Balance de Pagos: </strong>${{ paymentBalance || 'Cargando...' }}
      </div>
    </a>    

    <div
*ngIf="hasWallet && roleHasWallet"
(click)="openWalletModalCard()"
class="flex flex-col bg-gray-200 rounded-3xl p-2 cursor-pointer"
[ngClass]="{'pointer-events-none': hasRole('CAJERO')}"
>
<div class="user-balance mt-1 text-md text-gray-700 font-normal">
  Saldo envíos: {{ shipmentBalance | currency }}
</div>
<div class="user-balance mt-1 text-md text-gray-700 font-normal">
  Saldo servicios: {{ servicesBalance | currency }}
</div>
<div class="user-balance mt-1 text-md text-gray-700 font-normal">
  Saldo recargas: {{ recharguesBalance | currency }}
</div>
</div>
<div
*ngIf="hasWallet && parentHasWallet"
(click)="openWalletModalCard()"
class="flex flex-col bg-gray-200 rounded-3xl p-2 cursor-pointer"
[ngClass]="{'pointer-events-none': hasRole('CAJERO')}"
>
<div class="user-balance mt-1 text-md text-gray-700 font-normal">
  Saldo envíos: {{ shipmentBalance | currency }}
</div>
<div class="user-balance mt-1 text-md text-gray-700 font-normal">
  Saldo servicios: {{ servicesBalance | currency }}
</div>
<div class="user-balance mt-1 text-md text-gray-700 font-normal">
  Saldo recargas: {{ recharguesBalance | currency }}
</div>
</div>
    <div
      *ngIf="!hasWallet && roleHasWallet"
      class="flex flex-col bg-gray-200 rounded-3xl p-2"
    >
      <div class="user-balance mt-1 text-md text-gray-700 font-normal">
        Sin wallet
      </div>
      <button
        (click)="initializeWallet(id)"
        class="bg-dagblue text-white font-semibold rounded-full py-1 px-2 mt-2 hover:bg-dagblue-dark"
      >
        Crear wallet
      </button>
    </div>
  </div>
  <div class="h-full pb-4 overflow-y-auto">
    <ul class="space-y-2 ml-3 font-medium">
      <!-- Paquetes -->
      <li
        class="mt-2"
        *ngIf="
          hasPermission('Paquetes', 'Cotizar Envio') ||
          hasPermission('Paquetes', 'Envios') ||
          hasPermission('Paquetes', 'Recoleccion') ||
          hasPermission('Paquetes', 'Empaques')
        "
      >
        <div class="px-2 py-2">
          <button
            (click)="toggleSection('paquetes')"
            class="flex items-center w-full text-left"
          >
            <span class="font-bold uppercase text-sm flex-grow">Paquetes</span>
            <svg
              [ngClass]="{ 'rotate-180': isSectionOpen('paquetes') }"
              class="w-4 h-4 transition-transform duration-200"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <ul
          [@sectionAnimation]="isSectionOpen('paquetes') ? 'open' : 'closed'"
          class="pl-4 overflow-hidden"
        >
          <li class="mt-2" *ngIf="hasPermission('Paquetes', 'Cotizar Envio')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/quote'),
                'hover:bg-dagblue-light hover:bg-white hover:text-black':
                  !isCurrentPage('/quote')
              }"
            >
              <a
                [routerLink]="['/quote']"
                class="flex items-center py-2 rounded-l-full hover:text-black pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/quote'),
                  'px-4 text-white': !isCurrentPage('/quote')
                }"
              >
                <span> Cotizar Envio </span>
              </a>
            </div>
          </li>
          <li *ngIf="hasPermission('Paquetes', 'Envios')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/shipments'),
                'hover:bg-white hover:text-black': !isCurrentPage('/shipments')
              }"
            >
              <a
                [routerLink]="['/shipments']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/shipments'),
                  'px-4 text-white': !isCurrentPage('/shipments')
                }"
              >
                <span>Envios</span>
              </a>
            </div>
          </li>
          <li>
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/recoleccion'),
                'hover:bg-white hover:text-black': !isCurrentPage('/recoleccion')
              }"
            >
              <a
                [routerLink]="['/recoleccion']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/recoleccion'),
                  'px-4 text-white': !isCurrentPage('/recoleccion')
                }"
              >
                <span>Recoleccion</span>
              </a>
            </div>
          </li>
          
          <li *ngIf="hasPermission('Paquetes', 'Empaques')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/packing'),
                'hover:bg-white hover:text-black': !isCurrentPage('/packing')
              }"
            >
              <a
                [routerLink]="['/packing']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'text-black font-semibold': isCurrentPage('/packing'),
                  'text-white': !isCurrentPage('/packing')
                }"
              >
                <span>Empaques</span>
              </a>
            </div>
          </li>
        </ul>
      </li>
      <!-- Licenciatarios -->
      <li
        *ngIf="
          hasPermission('Licenciatarios', 'Resumen') ||
          hasPermission('Licenciatarios', 'Caja') ||
          hasPermission('Licenciatarios', 'Pago de Servicios') ||
          hasPermission('Licenciatarios', 'Solicitudes de Cancelacion') ||
          hasPermission('Licenciatarios', 'Dashboard de Inversor')
        "
      >
        <div class="px-2">
          <button
            (click)="toggleSection('licenciatarios')"
            class="flex items-center w-full text-left"
          >
            <span class="font-bold uppercase text-sm flex-grow"
              >Licenciatarios</span
            >
            <svg
              [ngClass]="{ 'rotate-180': isSectionOpen('licenciatarios') }"
              class="w-4 h-4 transition-transform duration-200"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <ul
          [@sectionAnimation]="
            isSectionOpen('licenciatarios') ? 'open' : 'closed'
          "
          class="pl-4 overflow-hidden"
        >
          <li *ngIf="hasPermission('Licenciatarios', 'Resumen')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/licensees'),
                'hover:bg-white hover:text-black': !isCurrentPage('/licensees')
              }"
            >
              <a
                [routerLink]="['/licensees']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/licensees'),
                  'px-4 text-white': !isCurrentPage('/licensees')
                }"
              >
                <span>Resumen</span>
              </a>
            </div>
          </li>
          <li *ngIf="hasPermission('Licenciatarios', 'Caja')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/cashiers'),
                'hover:bg-white hover:text-black': !isCurrentPage('/cashiers')
              }"
            >
              <a
                [routerLink]="['/cashiers']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/cashiers'),
                  'px-4 text-white': !isCurrentPage('/cashiers')
                }"
              >
                <span>Caja</span>
              </a>
            </div>
          </li>
          <!-- <li *ngIf="hasPermission('Licenciatarios', 'Cortes de Caja')"> -->
            <li >
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/cash-register-list-license/' + id),
                'hover:bg-white hover:text-black': !isCurrentPage('/cash-register-list-license/' + id)
              }"
            >
              <a
                [routerLink]="['/cash-register-list-license/' + id]"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/cash-register-list-license/' + id),
                  'px-4 text-white': !isCurrentPage('/cash-register-list-license/' + id)
                }"
              >
                <span>Cortes de caja</span>
              </a>
            </div>
          </li>
          <li *ngIf="hasPermission('Licenciatarios', 'Pago de Servicios')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/services'),
                'hover:bg-white hover:text-black': !isCurrentPage('/services')
              }"
            >
              <a
                [routerLink]="['/services']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/services'),
                  'px-4 text-white': !isCurrentPage('/services')
                }"
              >
                <span>Pago de servicios</span>
              </a>
            </div>

            <!-- <div class="hover:bg-white  hover:rounded-l-full hover:text-black" [ngClass]="{
                            'bg-white rounded-l-full': isCurrentPage('/scan'),
                            'hover:bg-white hover:text-black': !isCurrentPage('/scan')
                        }">
                            <a [routerLink]="['/scan']" 
                               class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                               [ngClass]="{
                                    'px-6 text-black font-semibold': isCurrentPage('/scan'),
                                    'px-4 text-white': !isCurrentPage('/scan')
                               }">
                                <span>Pagar Servicios</span>
                            </a>
                        </div> -->
          </li>
          <li *ngIf="hasPermission('Licenciatarios', 'Dashboard de Inversor')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/request-refill'),
                'hover:bg-white hover:text-black': !isCurrentPage('/request-refill')
              }"
            >
              <a
                [routerLink]="['/request-refill']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/request-refill'),
                  'px-4 text-white': !isCurrentPage('/request-refill')
                }"
              >
                <span>Solicitud Recarga</span>
              </a>
            </div>
          </li>
          <!-- <li
            *ngIf="
              hasPermission('Licenciatarios', 'Solicitudes de Cancelacion')
            "
          >
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/tickets'),
                'hover:bg-white hover:text-black': !isCurrentPage('/tickets')
              }"
            >

              <a
                [routerLink]="['/tickets']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/tickets'),
                  'px-4 text-white': !isCurrentPage('/tickets')
                }"
              >
                <span>Solicitudes de Cancelación</span>
              </a>
            </div>
          </li> -->
          <li *ngIf="hasPermission('Licenciatarios', 'Dashboard de Inversor')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/inversor'),
                'hover:bg-white hover:text-black': !isCurrentPage('/inversor')
              }"
            >
              <a
                [routerLink]="['/inversor']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/inversor'),
                  'px-4 text-white': !isCurrentPage('/inversor')
                }"
              >
                <span>Dashboard Inversor</span>
              </a>
            </div>
          </li>
        </ul>
      </li>
      <!-- Gestión Administrativa -->
      <li
        *ngIf="
          hasPermission('Gestion Administrativa', 'Dashboard General') ||
          hasPermission('Gestion Administrativa', 'Panel de Administracion') ||
          hasPermission('Gestion Administrativa', 'Solicitudes de Recarga') ||
          hasPermission('Gestion Administrativa', 'Cortes de Caja') ||
          hasPermission('Gestion Administrativa', 'Historial de Recargas')
        "
      >
        <div class="px-2">
          <button
            (click)="toggleSection('funciones')"
            class="flex items-center w-full text-left"
          >
            <span class="font-bold uppercase text-sm flex-grow"
              >Gestión Administrativa</span
            >
            <svg
              [ngClass]="{ 'rotate-180': isSectionOpen('funciones') }"
              class="w-4 h-4 transition-transform duration-200"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <ul
          [@sectionAnimation]="isSectionOpen('funciones') ? 'open' : 'closed'"
          class="pl-4 overflow-hidden"
        >
          <li
            *ngIf="hasPermission('Gestion Administrativa', 'Dashboard General')"
          >
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/administracion'),
                'hover:bg-white hover:text-black':
                  !isCurrentPage('/administracion')
              }"
            >
              <a
                [routerLink]="['/administracion']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold':
                    isCurrentPage('/administracion'),
                  'px-4 text-white': !isCurrentPage('/administracion')
                }"
              >
                <span>Dashboard General</span>
              </a>
            </div>
          </li>
          <li
            *ngIf="
              hasPermission('Gestion Administrativa', 'Panel de Administracion')
            "
          >
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/olimpo'),
                'hover:bg-white hover:text-black': !isCurrentPage('/olimpo')
              }"
            >
              <a
                [routerLink]="['/olimpo']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/olimpo'),
                  'px-4 text-white': !isCurrentPage('/olimpo')
                }"
              >
                <span>Panel de Administración</span>
              </a>
            </div>
          </li>
          <li
            *ngIf="
              hasPermission('Gestion Administrativa', 'Solicitudes de Recarga')
            "
          >
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/displayRefills'),
                'hover:bg-white hover:text-black':
                  !isCurrentPage('/displayRefills')
              }"
            >
              <a
                [routerLink]="['/displayRefills']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold':
                    isCurrentPage('/displayRefills'),
                  'px-4 text-white': !isCurrentPage('/displayRefills')
                }"
              >
                <span>Solcitudes de Recarga</span>
              </a>
            </div>
          </li>
          <li *ngIf="hasPermission('Gestion Administrativa', 'Cortes de Caja')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage(
                  '/cash-register-history'
                ),
                'hover:bg-white hover:text-black': !isCurrentPage(
                  '/cash-register-history'
                )
              }"
            >
              <a
                [routerLink]="['/cash-register-history']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage(
                    '/cash-register-history'
                  ),
                  'px-4 text-white': !isCurrentPage('/cash-register-history')
                }"
              >
                <span>Cortes de Caja</span>
              </a>
            </div>
          </li>

          <li
            *ngIf="
              hasPermission('Gestion Administrativa', 'Historial de Recargas')
            "
          >
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/History-Refills'),
                'hover:bg-white hover:text-black':
                  !isCurrentPage('/History-Refills')
              }"
            >
              <a
                [routerLink]="['/History-Refills']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold':
                    isCurrentPage('/History-Refills'),
                  'px-4 text-white': !isCurrentPage('/History-Refills')
                }"
              >
                <span>Historia de Recargas</span>
              </a>
            </div>
          </li>
          <li
           
          >
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/repartidor'),
                'hover:bg-white hover:text-black':
                  !isCurrentPage('/repartidor')
              }"
            >
              <a
                [routerLink]="['/repartidor']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold':
                    isCurrentPage('/repartidor'),
                  'px-4 text-white': !isCurrentPage('/repartidor')
                }"
              >
                <span>Repartidor</span>
              </a>
            </div>
          </li>
        </ul>
      </li>
      <!-- Lockers -->

      <li
        *ngIf="
          hasPermission('Lockers', 'Publicidad de Lockers') ||
          hasPermission('Lockers', 'Estatus de Lockers')
        "
      >
        <div class="px-2">
          <button
            (click)="toggleSection('funciones')"
            class="flex items-center w-full text-left"
          >
            <span class="font-bold uppercase text-sm flex-grow">LOCKERS</span>
            <svg
              [ngClass]="{ 'rotate-180': isSectionOpen('funciones') }"
              class="w-4 h-4 transition-transform duration-200"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <ul
          [@sectionAnimation]="isSectionOpen('funciones') ? 'open' : 'closed'"
          class="pl-4 overflow-hidden"
        >
          <li *ngIf="hasPermission('Lockers', 'Estatus de Lockers')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/viewlockers'),
                'hover:bg-white hover:text-black':
                  !isCurrentPage('/viewlockers')
              }"
            >
              <a
                [routerLink]="['/viewlockers']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold':
                    isCurrentPage('/viewlockers'),
                  'px-4 text-white': !isCurrentPage('/viewlockers')
                }"
              >
                <span>Estatus de Lockers</span>
              </a>
            </div>
          </li>
          <li *ngIf="hasPermission('Lockers', 'Publicidad de Lockers')">
            <div
              class="hover:bg-white hover:rounded-l-full hover:text-black"
              [ngClass]="{
                'bg-white rounded-l-full': isCurrentPage('/publicidad'),
                'hover:bg-white hover:text-black': !isCurrentPage('/publicidad')
              }"
            >
              <a
                [routerLink]="['/publicidad']"
                class="flex items-center hover:text-black py-2 rounded-l-full pl-8 pr-6 duration-100 text-sm w-full"
                [ngClass]="{
                  'px-6 text-black font-semibold': isCurrentPage('/publicidad'),
                  'px-4 text-white': !isCurrentPage('/publicidad')
                }"
              >
                <span>Publicidad del Lockers</span>
              </a>
            </div>
          </li>
        </ul>
      </li>
      <!-- Inversionistas -->
      <!-- <li *ngIf="hasRole('ADMIN')"></li>
                <div class="px-2">
                    <button (click)="toggleSection('inversionistas')" class="flex items-center w-full text-left">
                        <span class="font-bold uppercase text-sm flex-grow">inversionistas</span>
                        <svg [ngClass]="{'rotate-180': isSectionOpen('inversionistas')}" class="w-4 h-4 transition-transform duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <ul [@sectionAnimation]="isSectionOpen('inversionistas') ? 'open' : 'closed'" class="pl-4 overflow-hidden">
                    <li>
                        <button [routerLink]="['/inversor']" 
                                [ngClass]="{
                                    'bg-white text-black': isCurrentPage('/inversor'), 
                                    'bg-dagblue-light text-white hover:bg-white hover:text-black': !isCurrentPage('/inversor')
                                }" 
                                class="flex pl-2 pt-2 pb-2 rounded-lg duration-100 w-full text-sm">
                            <span class="ms-3 whitespace-nowrap">Dashboard Inversor</span>
                        </button>
                    </li>  
                </ul>   -->
    </ul>
  </div>
</aside>

<div *ngIf="walletCardModalIsOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
  <div class="bg-white w-[600px] rounded-xl shadow-2xl p-6">
    <!-- Sección de Saldos -->
    <div class="mb-6">
      <h2 class="text-2xl font-bold text-[#342C6A] mb-4">Mi Wallet</h2>
      <div class="grid grid-cols-3 gap-4">
        <div class="bg-gray-100 rounded-lg p-4 text-center">
          <span class="block text-sm text-gray-600">Saldo Envíos</span>
          <span class="block text-xl font-bold text-[#342C6A]">
            {{ shipmentBalance | currency }}
          </span>
        </div>
        <div class="bg-gray-100 rounded-lg p-4 text-center">
          <span class="block text-sm text-gray-600">Saldo Servicios</span>
          <span class="block text-xl font-bold text-[#342C6A]">
            {{ servicesBalance | currency }}
          </span>
        </div>
        <div class="bg-gray-100 rounded-lg p-4 text-center">
          <span class="block text-sm text-gray-600">Saldo Recargas</span>
          <span class="block text-xl font-bold text-[#342C6A]">
            {{ recharguesBalance | currency }}
          </span>
        </div>
      </div>
    </div>

    <!-- Últimas 5 Transacciones -->
    <div class="mb-6">
      <h3 class="text-lg font-semibold text-gray-800 mb-3">Últimas Transacciones</h3>
      <table class="w-full">
        <thead class="bg-gray-100">
          <tr>
            <th class="px-3 py-2 text-left text-xs text-gray-600">Fecha</th>
            <th class="px-3 py-2 text-left text-xs text-gray-600">Servicio</th>
            <th class="px-3 py-2 text-left text-xs text-gray-600">Monto</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let transaction of transactionHistory.slice(0, 5)" class="border-b last:border-b-0">
            <td class="px-3 py-2 text-sm">
              {{ transaction.transaction_date | date:'dd/MM/yyyy HH:mm' }}
            </td>
            <td class="px-3 py-2 text-sm">
              {{ transaction.service }}
            </td>
            <td
              [ngClass]="{
                'text-green-600': transaction.payment_method === 'Reembolso' || transaction.service === 'Abono a wallet',
                'text-red-600': transaction.payment_method !== 'Reembolso' && transaction.service !== 'Abono a wallet'
              }"
              class="px-4 py-3 text-sm font-semibold flex items-center space-x-1"
            >
              <span>
                {{
                  (transaction.payment_method === 'Reembolso' || transaction.service === 'Abono a wallet') ? '+' : '-'
                }}
              </span>
              <span>
                {{ transaction.amount?.$numberDecimal || "vacio" }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Sección de Cajeros -->
    <div class="cash-transaction-history overflow-x-auto">
      <!-- Verificamos si hay transacciones disponibles -->
      <div *ngIf="cashTransactionHistory.length > 0; else noTransactions">
        <table class="min-w-full table-auto text-left border-collapse">
          <thead>
            <tr class="bg-gray-200">
              <th class="px-4 py-2 border-b">Caja Registradora</th>
              <th class="px-4 py-2 border-b">Operador</th>
              <th class="px-4 py-2 border-b">Email</th>
              <th class="px-4 py-2 border-b">Fecha</th>
              <th class="px-4 py-2 border-b">Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cashier of cashTransactionHistory">
              <td class="px-4 py-2 border-b font-semibold">{{ cashier.name }}</td>
              <td class="px-4 py-2 border-b">
                <div *ngFor="let transaction of cashier.transactions">
                  {{ transaction.operation_by.name }}
                </div>
              </td>
              <td class="px-4 py-2 border-b">
                <div *ngFor="let transaction of cashier.transactions">
                  {{ transaction.operation_by.email }}
                </div>
              </td>
              <td class="px-4 py-2 border-b">
                <div *ngFor="let transaction of cashier.transactions">
                  {{ transaction.createdAt | date:'short' }}
                </div>
              </td>
              <td class="px-4 py-2 border-b">
                <div *ngFor="let transaction of cashier.transactions">
                  {{ transaction.amount | currency:'USD' }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    
      <!-- Mensaje en caso de que no haya transacciones -->
      <ng-template #noTransactions>
        <p class="text-center text-gray-500">No hay transacciones registradas.</p>
      </ng-template>
    </div>
    

    <!-- Botones de Acción -->
    <div class="flex justify-between items-center mt-6">
      <button 
        (click)="closeWalletModalCard()"
        class="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
      >
        Cerrar
      </button>
      <button 
        (click)="openWalletModal()"
        class="px-4 py-2 bg-[#342C6A] text-white rounded-md hover:bg-opacity-90 transition-colors"
      >
        Ver Historial Completo
      </button>
    </div>
  </div>
</div>


<div
  *ngIf="walletModalIsOpen"
  class="fixed inset-0 z-50 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4"
>
  <div
    class="bg-white rounded-xl shadow-2xl w-full max-w-6xl max-h-[90vh] flex flex-col overflow-hidden"
  >
    <!-- Modal Header -->
    <div
      class="bg-[#342C6A] text-white px-6 py-4 flex justify-between items-center"
    >
      <h2 class="text-2xl font-bold">Historial de transacciones recientes</h2>
      <button
        (click)="closeModal()"
        class="text-white hover:text-gray-200 transition-colors"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="flex-grow p-6 overflow-auto">
      <div class="w-full bg-white rounded-lg p-4">
        <!-- Table Controls -->
        <div class="flex justify-between items-center mb-4">
          <div class="flex items-center space-x-2">
            <label for="pageSize" class="text-sm text-gray-600">Mostrar</label>
            <select
              id="pageSize"
              (change)="onPageSizeChange($event)"
              class="form-select px-3 py-2 border rounded-md text-sm focus:ring-2 focus:ring-[#342C6A]/50"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
          <p class="text-sm text-gray-600">
            Total de elementos: {{ totalItemsTransaction }}
          </p>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto">
          <table class="min-w-full bg-white shadow-md rounded-lg">
            <thead class="bg-gray-100">
              <tr>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Fecha
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Servicio
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Tipo
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Método de Pago
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Monto
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Saldo anterior
                </th>
                <th
                  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Saldo nuevo
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr *ngIf="transactionHistory.length === 0">
                <td colspan="7" class="px-4 py-3 text-center text-gray-500">
                  No hay transacciones para mostrar
                </td>
              </tr>
              <tr
                *ngFor="let transaction of transactionHistory"
                class="hover:bg-gray-50 transition-colors"
              >
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.transaction_date | date : "dd/MM/yyyy HH:mm" }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.service }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.details }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.payment_method }}
                </td>
                <td
                [ngClass]="{
                  'text-green-600': transaction.payment_method === 'Reembolso' || transaction.service === 'Abono a wallet',
                  'text-red-600': transaction.payment_method !== 'Reembolso' && transaction.service !== 'Abono a wallet'
                }"
                class="px-4 py-3 text-sm font-semibold flex items-center space-x-1"
              >
                <span>
                  {{
                    (transaction.payment_method === 'Reembolso' || transaction.service === 'Abono a wallet')
                      ? '+'
                      : '-'
                  }}
                </span>
                <span>
                  {{ transaction.amount?.$numberDecimal || "vacio" }}
                </span>
              </td>
              
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.previous_balance?.$numberDecimal || "vacio" }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ transaction.new_balance?.$numberDecimal || "vacio" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal Footer -->
    <div class="mt-4 flex justify-between items-center p-4">
      <!-- Botón para cerrar -->
      <button
        (click)="closeModal()"
        class="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
      >
        Volver
      </button>

      <!-- Contenedor de paginación -->
      <div class="flex items-center space-x-4">
        <!-- Botón Anterior -->
        <button
          (click)="previousPage()"
          [disabled]="!hasPreviousPage"
          class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
        >
          Anterior
        </button>

        <!-- Números de página -->
        <div class="flex space-x-1">
          <button
            *ngFor="let page of getPageNumbers()"
            (click)="goToPage(page)"
            [ngClass]="{
              'bg-[#342C6A] text-white': page === currentPageTransaction,
              'bg-gray-300 text-gray-800': page !== currentPageTransaction
            }"
            class="px-4 py-2 rounded-md"
          >
            {{ page }}
          </button>
        </div>

        <!-- Botón Siguiente -->
        <button
          (click)="nextPage()"
          [disabled]="!hasNextPage"
          class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</div>


<!-- Modal de notificacion -->

<div
  *ngIf="isNotificationModalOpen"
  class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
>
  <div class="bg-white rounded-lg shadow-lg w-96 p-6">
    <!-- Modal Header -->
    <div class="flex justify-between items-center border-b pb-2">
      <h2 class="text-xl font-bold">Notificaciones</h2>
      <button (click)="closeNotificationModal()" class="text-gray-500 hover:text-gray-700">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
    <!-- Modal Content -->
    <div class="mt-4">
      <p class="text-gray-600">Aquí aparecerán tus notificaciones recientes.</p>
    </div>
    <!-- Modal Footer -->
    <div class="mt-4 flex justify-end">
      <button (click)="closeNotificationModal()" class="bg-dagblue text-white px-4 py-2 rounded-lg">
        Cerrar
      </button>
    </div>
  </div>
</div>

