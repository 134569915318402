<app-sidebar></app-sidebar>
<div class="sm:ml-64 mt-14 bg-gray-100 min-h-screen">
  <div class="p-4 sm:p-6 lg:p-8">
    <!-- Título -->
    <h1 class="font-bold text-dagblue uppercase text-2xl sm:text-3xl mb-6">
      Estatus de los Repartidores
    </h1>
    <p class="text-gray-600 text-sm sm:text-base mb-4">
      Detalles de los repartidores y sus áreas de entrega
    </p>
    <!-- Muestra un mensaje de error si es necesario -->
    <div *ngIf="errorMessage" class="text-red-600 text-center p-4">
      {{ errorMessage }}
    </div>

    <!-- Tabla -->
    <div
      class="bg-[#F0F0F0] rounded-lg shadow-md overflow-x-auto"
      *ngIf="repartidores.length > 0 && !isLoading"
    >
      <table class="w-full text-sm sm:text-base">
        <thead class="uppercase bg-[#F0F0F0] text-dagblue font-bold">
          <tr>
            <th class="px-4 py-2 text-left">ID</th>
            <th class="px-4 py-2 text-left">Nombre</th>
            <th class="px-4 py-2 text-left">Correo Electrónico</th>
            <th class="px-4 py-2 text-center">Estado</th>
            <th class="px-4 py-2 text-center">Acciones</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 text-gray-500">
          <tr *ngFor="let repartidor of repartidores" class="hover:bg-gray-50">
            <td class="px-4 py-2">{{ repartidor._id }}</td>
            <td class="px-4 py-2">{{ repartidor.name }}</td>
            <td class="px-4 py-2">{{ repartidor.email }}</td>
            <td class="px-4 py-2 text-center">
              <span
                [ngClass]="{
                  'px-2 py-1 rounded-full text-lg font-bold': true,
                  'text-green-600': repartidor.active,
                  'text-red-600': !repartidor.active
                }"
              >
                {{ repartidor.active ? "Activo" : "Inactivo" }}
              </span>
            </td>
            <td class="px-4 py-2 text-center">
              <button
                (click)="viewDetails(repartidor._id)"
                class="w-full sm:w-auto bg-dagblue font-bold text-white px-4 py-2 rounded-full hover:bg-blue-900 transition"
              >
                Ver
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div
  class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  *ngIf="viewModal"
>
  <div class="bg-white rounded-lg w-full max-w-4xl mx-4 p-6">
    <!-- Header del modal -->
    <div class="flex justify-between items-center mb-6">
      <h2 class="text-2xl font-bold text-dagblue">Detalles de Envíos</h2>
      <button
        (click)="viewModal = false"
        class="text-gray-500 hover:text-gray-700"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
    </div>

    <!-- Filtros -->
    <div class="flex gap-4 mb-6">
      <button
        *ngFor="
          let status of ['todos', 'envio asignado', 'en camino', 'entregado']
        "
        (click)="filterShipments(status)"
        [ngClass]="{
          'px-4 py-2 rounded-full text-sm font-medium': true,
          'bg-dagblue text-white': currentFilter === status,
          'bg-gray-200 text-gray-700': currentFilter !== status
        }"
      >
        {{ status | titlecase }}
      </button>
    </div>

    <!-- Lista de envíos -->
    <div class="max-h-[60vh] overflow-y-auto">
      <div
        *ngFor="let shipment of filteredShipments"
        class="bg-gray-50 rounded-lg p-4 mb-4 shadow-sm"
      >
        <div class="grid grid-cols-2 gap-4">
          <!-- ID y Estado -->
          <div>
            <p class="text-sm text-gray-600">ID del envío:</p>
            <p class="font-medium">{{ shipment.shipment_id }}</p>
          </div>
          <div>
            <p class="text-sm text-gray-600">Estado:</p>
            <p
              [ngClass]="{
                'font-medium rounded-full px-3 py-1 text-sm inline-block': true,
                'bg-yellow-100 text-yellow-800':
                  shipment.titulo === 'Envio Asignado',
                'bg-blue-100 text-blue-800': shipment.titulo === 'En Camino',
                'bg-green-100 text-green-800': shipment.titulo === 'Entregado'
              }"
            >
              {{ shipment.titulo }}
            </p>
          </div>

          <!-- Fecha y Descripción -->
          <div>
            <p class="text-sm text-gray-600">Fecha:</p>
            <p class="font-medium">{{ shipment.fecha | date : "medium" }}</p>
          </div>
          <div>
            <p class="text-sm text-gray-600">Descripción:</p>
            <p class="font-medium">{{ shipment.descripcion }}</p>
          </div>

          <!-- Detalles de envío -->
          <div>
            <p class="text-sm text-gray-600">Origen:</p>
            <p class="font-medium">{{ shipment.detalles_envio.origen }}</p>
          </div>
          <div>
            <p class="text-sm text-gray-600">Destino:</p>
            <p class="font-medium">{{ shipment.detalles_envio.destino }}</p>
          </div>
        </div>
      </div>

      <!-- Mensaje cuando no hay envíos -->
      <div
        *ngIf="filteredShipments.length === 0"
        class="text-center py-8 text-gray-500"
      >
        No hay envíos para mostrar
      </div>
    </div>
  </div>
</div>
