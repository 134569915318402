import { Injectable } from '@angular/core';
import { AuthLoginService } from '../users/auth-login.service';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  private apiUrl = environment.apiUrl;
  
  constructor(
    
    private http: HttpClient,
    private authService: AuthLoginService
    
  ) {}

  // Métodos relacionados con entregas
  asignDelivery(data: { shipmentId: string, userId: string }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/assign-shipment`, data);
  }

  viewShipmentsByDeliveryman(data: { user_id: string, status?: string }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/shipments-delivery`, data);
  }
}
