<app-sidebar></app-sidebar>
<div class="sm:ml-64 mt-14 flex flex-col md:flex-row">
  <!-- Barra de categorías -->
  <div class="w-full md:w-1/5 bg-gray-100 p-4 overflow-y-auto h-auto md:h-screen">
    <h2 class="text-lg sm:text-xl font-bold mb-4">Categorías</h2>
    <ul>
      <li *ngFor="let category of categories">
        <!-- Categoría principal -->
        <div
          (click)="selectCategory(category)"
          class="cursor-pointer p-2 hover:bg-gray-200 rounded text-sm uppercase"
          [ngClass]="{ 'bg-blue-200': selectedCategory === category }"
        >
          {{ category }}
        </div>

        <ul *ngIf="selectedCategory === category">
          <!-- Subcategorías (si existen y no son N/A) -->
          <ng-container *ngIf="hasSubcategories(category)">
            <li *ngFor="let subcategory of getSubcategories(category)" class="ml-4">
              <div
                (click)="selectSubcategory(subcategory)"
                class="cursor-pointer p-2 hover:bg-gray-200 rounded text-sm"
                [ngClass]="{ 'bg-blue-100': selectedSubcategory === subcategory }"
              >
                {{ subcategory }}
              </div>

              <!-- Carriers bajo subcategoría -->
              <ul *ngIf="selectedSubcategory === subcategory">
                <li
                  *ngFor="let carrier of getCarriersBySubcategory(category, subcategory)"
                  (click)="selectCarrier(carrier)"
                  class="cursor-pointer p-2 ml-6 hover:bg-gray-200 rounded text-sm"
                  [ngClass]="{ 'bg-blue-50': selectedCarrier === carrier }"
                >
                  {{ carrier }}
                </li>
              </ul>
            </li>
          </ng-container>

          <!-- Carriers directos (para servicios con N/A) -->
          <li
            *ngFor="let carrier of getCarriers(category)"
            (click)="selectCarrier(carrier)"
            class="cursor-pointer p-2 ml-4 hover:bg-gray-200 rounded text-sm"
            [ngClass]="{ 'bg-blue-100': selectedCarrier === carrier }"
          >
            {{ carrier }}
          </li>
        </ul>
      </li>
    </ul>
  </div>

   <!-- Área de tarjetas de servicios a la derecha -->
   <div class="w-full md:w-4/5 p-4 overflow-y-auto h-auto md:h-screen">
    <h2 class="text-xl sm:text-2xl font-bold mb-4">{{ selectedCategory }}</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <div
        *ngFor="let service of getFilteredServices()"
        class="bg-white p-4 rounded shadow hover:shadow-lg transition"
      >
        <img
          [src]="getURLImage(service)"
          [alt]="service.CarrierName"
          class="w-full h-32 object-contain mb-2"
        />
        <h3 class="text-base sm:text-lg font-semibold">
          {{ service.ProductName }}
        </h3>
        <p class="text-sm">{{ getToolTip(service) }}</p>
        <p class="text-sm font-semibold mt-2" *ngIf="service.Amount !== '0'">
          Precio: ${{ service.Amount }}
        </p>
        <p class="text-sm font-semibold mt-2" *ngIf="service.Amount === '0'">
          Monto mínimo: ${{ service.AmountMin }} - Monto máximo: ${{
            service.AmountMax
          }}
        </p>
        Tipo de pago: {{ service.PaymentType }}

        <div class="flex justify-end">
          <button
            (click)="openPaymentModal(service)"
            class="px-4 sm:px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition"
          >
            Pagar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal de pago -->
<div
  *ngIf="showPaymentModal && selectedService"
  class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
>
  <div class="bg-white p-6 rounded-lg max-w-xs sm:max-w-md w-full relative">
    <button
      (click)="cancelPayment()"
      class="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>

    <h2 class="text-lg sm:text-xl font-bold mb-4">
      Pago {{ selectedService.ProductName }}
    </h2>

    <div class="mb-4" *ngIf="selectedService.Amount !== '0'">
      <p class="font-semibold">Monto original: ${{ selectedPrice }}</p>
      <p class="font-semibold">Comisión aplicada: ${{ commission }}</p>
      <p class="font-semibold">Total a pagar: ${{ totalAmount }}</p>
    </div>

    <div class="mb-4" *ngIf="selectedService.Amount === '0'">
      <label class="block text-sm font-medium text-gray-700"
        >Monto a pagar</label
      >
      <input
        [(ngModel)]="selectedPrice"
        type="number"
        [min]="selectedService.AmountMin"
        [max]="selectedService.AmountMax"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2"
        placeholder="Ingrese el monto"
      />
    </div>

    <!-- Referencia dinámica -->
    <ng-container *ngFor="let refEntry of selectedService.ReferenceParameters | keyvalue">
      <div *ngIf="!isArray(refEntry.value)">
        <label class="block text-sm font-medium text-gray-700">
          {{ refEntry.value.ReferenceName }}
        </label>
        <input
          [(ngModel)]="inputValues[refEntry.key]"
          type="text"
          [placeholder]="refEntry.value.ToolTip"
          class="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          [attr.minlength]="refEntry.value.LengthMin"
          [attr.maxlength]="refEntry.value.LengthMax"
        />
    
        <!-- Nuevo Input para Confirmación -->
        <label class="block text-sm font-medium text-gray-700 mt-4">
          Confirme {{ refEntry.value.ReferenceName }}
        </label>
        <input
          [(ngModel)]="confirmInputValues[refEntry.key]"
          type="text"
          [placeholder]="'Confirme ' + refEntry.value.ToolTip"
          class="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          [attr.minlength]="refEntry.value.LengthMin"
          [attr.maxlength]="refEntry.value.LengthMax"
        />
    
        <!-- Mensaje de error si los valores no coinciden -->
        <div *ngIf="inputValues[refEntry.key] && confirmInputValues[refEntry.key] && inputValues[refEntry.key] !== confirmInputValues[refEntry.key]" class="text-red-500 text-sm mt-2">
          Los valores no coinciden.
        </div>
      </div>
    
      <!-- Select para valores tipo array -->
      <div *ngIf="isArray(refEntry.value)" class="mb-4">
        <label class="block text-sm font-medium text-gray-700">
          {{ refEntry.value[0].ReferenceName }}
        </label>
        <select
          [(ngModel)]="referenceValues[refEntry.key]"
          class="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="">Seleccione una opción</option>
          <option *ngFor="let option of refEntry.value" [value]="option.LengthMin">
            {{ option.ToolTip }} ({{ option.LengthMin }} dígitos)
          </option>
        </select>
      </div>
    </ng-container>
    

    <div class="flex justify-end mt-6">
      <button
        (click)="cancelPayment()"
        class="mr-4 px-6 py-2 bg-gray-300 text-gray-800 rounded-lg"
      >
        Cancelar
      </button>
      <button
        (click)="openPinModal()"
        class="px-6 py-2 bg-orange-500 text-white rounded-lg"
        [disabled]="!areInputsValid()"
      >
        Aceptar
      </button>

    </div>
  </div>
</div>

<!-- Modal de NIP -->
<div *ngIf="showPinModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
  <div class="bg-white p-6 rounded-lg max-w-xs sm:max-w-md w-full relative">
    <button (click)="closePinModal()" class="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <h3 class="text-lg font-bold mb-4">Ingresar NIP</h3>
    <form (ngSubmit)="validateNip()">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="nipInput">NIP de seguridad</label>
        <input
          type="password"
          id="nipInput"
          [(ngModel)]="enteredPin"
          name="nipInput"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div class="flex items-center justify-between">
        <button type="submit" class="bg-dagblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Validar NIP
        </button>
        <button type="button" (click)="closePinModal()" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Cancelar
        </button>
      </div>
    </form>
  </div>
</div>
